

import React from "react"

function SvgThreeIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="9.99988" r="9.25" stroke="#B4B4FA" strokeWidth="0.5" />
            <path d="M15.2404 16.4999H5.14423C5.06447 16.4999 5 16.4343 5 16.3531V3.14662C5 3.06547 5.06447 2.99988 5.14423 2.99988H15.2404C15.3201 2.99988 15.3846 3.06547 15.3846 3.14662V16.3531C15.3846 16.4343 15.32 16.4999 15.2404 16.4999ZM5.28846 16.2064H15.0962V3.29336H5.28846V16.2064Z" fill="#B4B4FA" />
            <path d="M14.2193 4.17168H6.16505C6.09498 4.17168 6.03833 4.14187 6.03833 4.105C6.03833 4.06814 6.09498 4.03833 6.16505 4.03833H14.2193C14.2894 4.03833 14.346 4.06814 14.346 4.105C14.346 4.14187 14.2891 4.17168 14.2193 4.17168Z" fill="#B4B4FA" />
            <path d="M14.2193 6.2487H6.16505C6.09498 6.2487 6.03833 6.2189 6.03833 6.18203C6.03833 6.14516 6.09498 6.11536 6.16505 6.11536H14.2193C14.2894 6.11536 14.346 6.14516 14.346 6.18203C14.346 6.2189 14.2891 6.2487 14.2193 6.2487Z" fill="#B4B4FA" />
            <path d="M14.2193 8.32548H6.16505C6.09498 8.32548 6.03833 8.29568 6.03833 8.25881C6.03833 8.22194 6.09498 8.19214 6.16505 8.19214H14.2193C14.2894 8.19214 14.346 8.22194 14.346 8.25881C14.346 8.29568 14.2891 8.32548 14.2193 8.32548Z" fill="#B4B4FA" />
            <path d="M14.2193 10.4024H6.16505C6.09498 10.4024 6.03833 10.3726 6.03833 10.3357C6.03833 10.2988 6.09498 10.269 6.16505 10.269H14.2193C14.2894 10.269 14.346 10.2988 14.346 10.3357C14.346 10.3726 14.2891 10.4024 14.2193 10.4024Z" fill="#B4B4FA" />
            <path d="M14.2193 12.4794H6.16505C6.09498 12.4794 6.03833 12.4496 6.03833 12.4127C6.03833 12.3759 6.09498 12.3461 6.16505 12.3461H14.2193C14.2894 12.3461 14.346 12.3759 14.346 12.4127C14.346 12.4496 14.2891 12.4794 14.2193 12.4794Z" fill="#B4B4FA" />
            <path d="M14.2353 4.69096H7.18778C7.12647 4.69096 7.0769 4.66116 7.0769 4.62429C7.0769 4.58742 7.12647 4.55762 7.18778 4.55762H14.2353C14.2966 4.55762 14.3461 4.58742 14.3461 4.62429C14.3461 4.66116 14.2963 4.69096 14.2353 4.69096Z" fill="#B4B4FA" />
            <path d="M14.2353 6.76775H7.18778C7.12647 6.76775 7.0769 6.73794 7.0769 6.70107C7.0769 6.6642 7.12647 6.6344 7.18778 6.6344H14.2353C14.2966 6.6344 14.3461 6.6642 14.3461 6.70107C14.3461 6.73794 14.2963 6.76775 14.2353 6.76775Z" fill="#B4B4FA" />
            <path d="M14.2353 8.84489H7.18778C7.12647 8.84489 7.0769 8.81509 7.0769 8.77822C7.0769 8.74135 7.12647 8.71155 7.18778 8.71155H14.2353C14.2966 8.71155 14.3461 8.74135 14.3461 8.77822C14.3461 8.81509 14.2963 8.84489 14.2353 8.84489Z" fill="#B4B4FA" />
            <path d="M14.2353 10.9217H7.18778C7.12647 10.9217 7.0769 10.8919 7.0769 10.855C7.0769 10.8181 7.12647 10.7883 7.18778 10.7883H14.2353C14.2966 10.7883 14.3461 10.8181 14.3461 10.855C14.3461 10.8919 14.2963 10.9217 14.2353 10.9217Z" fill="#B4B4FA" />
            <path d="M14.2353 12.9985H7.18778C7.12647 12.9985 7.0769 12.9687 7.0769 12.9318C7.0769 12.8949 7.12647 12.8651 7.18778 12.8651H14.2353C14.2966 12.8651 14.3461 12.8949 14.3461 12.9318C14.3461 12.9687 14.2963 12.9985 14.2353 12.9985Z" fill="#B4B4FA" />
            <path d="M14.2353 5.21001H7.18778C7.12647 5.21001 7.0769 5.1802 7.0769 5.14333C7.0769 5.10647 7.12647 5.07666 7.18778 5.07666H14.2353C14.2966 5.07666 14.3461 5.10647 14.3461 5.14333C14.3461 5.1802 14.2963 5.21001 14.2353 5.21001Z" fill="#B4B4FA" />
            <path d="M14.2353 7.28715H7.18778C7.12647 7.28715 7.0769 7.25735 7.0769 7.22048C7.0769 7.18361 7.12647 7.15381 7.18778 7.15381H14.2353C14.2966 7.15381 14.3461 7.18361 14.3461 7.22048C14.3461 7.25735 14.2963 7.28715 14.2353 7.28715Z" fill="#B4B4FA" />
            <path d="M14.2353 9.36394H7.18778C7.12647 9.36394 7.0769 9.33413 7.0769 9.29726C7.0769 9.2604 7.12647 9.23059 7.18778 9.23059H14.2353C14.2966 9.23059 14.3461 9.2604 14.3461 9.29726C14.3461 9.33413 14.2963 9.36394 14.2353 9.36394Z" fill="#B4B4FA" />
            <path d="M14.2353 11.441H7.18778C7.12647 11.441 7.0769 11.4112 7.0769 11.3743C7.0769 11.3374 7.12647 11.3076 7.18778 11.3076H14.2353C14.2966 11.3076 14.3461 11.3374 14.3461 11.3743C14.3461 11.4112 14.2963 11.441 14.2353 11.441Z" fill="#B4B4FA" />
            <path d="M14.2353 13.5179H7.18778C7.12647 13.5179 7.0769 13.4881 7.0769 13.4512C7.0769 13.4143 7.12647 13.3845 7.18778 13.3845H14.2353C14.2966 13.3845 14.3461 13.4143 14.3461 13.4512C14.3461 13.4881 14.2963 13.5179 14.2353 13.5179Z" fill="#B4B4FA" />
        </svg>

    );
}

export default SvgThreeIcon;