



import React from "react"

function FilterTypeTwo() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2063_4055)">
                <path d="M0.609756 7.38406C0.945122 7.38406 1.21951 7.10967 1.21951 6.7743V3.87796H4.11585C4.45122 3.87796 4.72561 3.60357 4.72561 3.2682C4.72561 2.93284 4.45122 2.65845 4.11585 2.65845H0.609756C0.27439 2.65845 0 2.93284 0 3.2682V6.7743C0 7.10967 0.27439 7.38406 0.609756 7.38406Z" fill="#B4B4FA" />
                <path d="M3.20122 17.2926H1.21951V14.3963C1.21951 14.0609 0.945122 13.7865 0.609756 13.7865C0.27439 13.7865 0 14.0609 0 14.3963V17.9024C0 18.2377 0.27439 18.5121 0.609756 18.5121H3.20122C3.53659 18.5121 3.81098 18.2377 3.81098 17.9024C3.81098 17.567 3.53659 17.2926 3.20122 17.2926Z" fill="#B4B4FA" />
                <path d="M18.9025 2.65845H15.3964C15.061 2.65845 14.7866 2.93284 14.7866 3.2682C14.7866 3.60357 15.061 3.87796 15.3964 3.87796H18.2927V5.24991C18.2927 5.58528 18.5671 5.55479 18.9025 5.55479C19.2378 5.55479 19.5122 5.58528 19.5122 5.24991V3.2682C19.5122 2.93284 19.2378 2.65845 18.9025 2.65845Z" fill="#B4B4FA" />
            </g>
            <g clipPath="url(#clip1_2063_4055)">
                <path d="M3.35365 9.21316C3.68902 9.21316 3.96341 8.93877 3.96341 8.6034V5.70706H6.85975C7.19512 5.70706 7.46951 5.43267 7.46951 5.0973C7.46951 4.76194 7.19512 4.48755 6.85975 4.48755H3.35365C3.01829 4.48755 2.7439 4.76194 2.7439 5.0973V8.6034C2.7439 8.93877 3.01829 9.21316 3.35365 9.21316Z" fill="#B4B4FA" />
                <path d="M5.79268 19.1217H3.96341V16.2254C3.96341 15.89 3.68902 15.6156 3.35365 15.6156C3.01829 15.6156 2.7439 15.89 2.7439 16.2254V19.7315C2.7439 20.0668 3.01829 20.3412 3.35365 20.3412H5.79268C6.12804 20.3412 6.40243 20.0668 6.40243 19.7315C6.40243 19.3961 6.12804 19.1217 5.79268 19.1217Z" fill="#B4B4FA" />
                <path d="M21.6464 4.48755H18.1403C17.8049 4.48755 17.5305 4.76194 17.5305 5.0973C17.5305 5.43267 17.8049 5.70706 18.1403 5.70706H21.0366V6.92657C21.0366 7.26194 21.311 7.53633 21.6464 7.53633C21.9817 7.53633 22.2561 7.26194 22.2561 6.92657V5.0973C22.2561 4.76194 21.9817 4.48755 21.6464 4.48755Z" fill="#B4B4FA" />
            </g>
            <g clipPath="url(#clip2_2063_4055)">
                <path d="M6.0973 11.0426C6.43267 11.0426 6.70706 10.7682 6.70706 10.4329V7.53653H9.6034C9.93877 7.53653 10.2132 7.26214 10.2132 6.92677C10.2132 6.59141 9.93877 6.31702 9.6034 6.31702H6.0973C5.76194 6.31702 5.48755 6.59141 5.48755 6.92677V10.4329C5.48755 10.7682 5.76194 11.0426 6.0973 11.0426Z" fill="#B4B4FA" />
                <path d="M9.6034 20.9512H6.70706V18.0548C6.70706 17.7195 6.43267 17.4451 6.0973 17.4451C5.76194 17.4451 5.48755 17.7195 5.48755 18.0548V21.5609C5.48755 21.8963 5.76194 22.1707 6.0973 22.1707H9.6034C9.93877 22.1707 10.2132 21.8963 10.2132 21.5609C10.2132 21.2256 9.93877 20.9512 9.6034 20.9512Z" fill="#B4B4FA" />
                <path d="M24.39 17.4451C24.0547 17.4451 23.7803 17.7195 23.7803 18.0548V20.9512H20.8839C20.5486 20.9512 20.2742 21.2256 20.2742 21.5609C20.2742 21.8963 20.5486 22.1707 20.8839 22.1707H24.39C24.7254 22.1707 24.9998 21.8963 24.9998 21.5609V18.0548C24.9998 17.7195 24.7254 17.4451 24.39 17.4451Z" fill="#B4B4FA" />
                <path d="M24.39 6.31702H20.8839C20.5486 6.31702 20.2742 6.59141 20.2742 6.92677C20.2742 7.26214 20.5486 7.53653 20.8839 7.53653H23.7803V10.4329C23.7803 10.7682 24.0547 11.0426 24.39 11.0426C24.7254 11.0426 24.9998 10.7682 24.9998 10.4329V6.92677C24.9998 6.59141 24.7254 6.31702 24.39 6.31702Z" fill="#B4B4FA" />
                <path d="M18.1706 14.8536H20.5182C20.8535 14.8536 21.1279 14.5793 21.1279 14.2439C21.1279 13.9085 20.8535 13.6341 20.5182 13.6341H18.1706C17.9267 12.4756 17.0121 11.561 15.8535 11.3171V8.9695C15.8535 8.63413 15.5791 8.35974 15.2438 8.35974C14.9084 8.35974 14.634 8.63413 14.634 8.9695V11.3171C13.4755 11.561 12.5608 12.4756 12.3169 13.6341H9.96938C9.63401 13.6341 9.35962 13.9085 9.35962 14.2439C9.35962 14.5793 9.63401 14.8536 9.96938 14.8536H12.3169C12.5608 16.0122 13.4755 16.9268 14.634 17.1707V19.5183C14.634 19.8536 14.9084 20.128 15.2438 20.128C15.5791 20.128 15.8535 19.8536 15.8535 19.5183V17.1707C17.0121 16.9268 17.9267 16.0122 18.1706 14.8536ZM15.2438 16.0122C14.2682 16.0122 13.4755 15.2195 13.4755 14.2439C13.4755 13.2683 14.2682 12.4756 15.2438 12.4756C16.2194 12.4756 17.0121 13.2683 17.0121 14.2439C17.0121 15.2195 16.2194 16.0122 15.2438 16.0122Z" fill="#B4B4FA" />
            </g>
            <defs>
                <clipPath id="clip0_2063_4055">
                    <rect width="19.5122" height="19.5122" fill="white" transform="translate(0 0.829224)" />
                </clipPath>
                <clipPath id="clip1_2063_4055">
                    <rect width="19.5122" height="19.5122" fill="white" transform="translate(2.7439 2.65833)" />
                </clipPath>
                <clipPath id="clip2_2063_4055">
                    <rect width="19.5122" height="19.5122" fill="white" transform="translate(5.48755 4.48779)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default FilterTypeTwo;