

import React from "react"

function SvgOneIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="9.99988" r="9.25" stroke="#B4B4FA" strokeWidth="0.5" />
            <g clipPath="url(#clip0_2022_8678)">
                <path d="M15.7686 13.384H6.44587C5.76681 13.384 5.21436 12.8328 5.21436 12.1549V5.7738C5.21436 5.09603 5.76681 4.54468 6.44587 4.54468H15.7686C16.4476 4.54468 17.0001 5.09603 17.0001 5.7738V12.1548C17.0001 12.8326 16.4476 13.384 15.7686 13.384ZM6.44587 5.28128C6.17296 5.28128 5.95096 5.50218 5.95096 5.7738V12.1549C5.95096 12.4264 6.17296 12.6474 6.44587 12.6474H15.7686C16.0415 12.6474 16.2635 12.4266 16.2635 12.1548V5.7738C16.2635 5.50218 16.0415 5.28128 15.7686 5.28128H6.44587Z" fill="#B4B4FA" />
                <path d="M9.51803 7.95318C9.95394 7.95318 10.3073 7.59981 10.3073 7.16391C10.3073 6.728 9.95394 6.37463 9.51803 6.37463C9.08213 6.37463 8.72876 6.728 8.72876 7.16391C8.72876 7.59981 9.08213 7.95318 9.51803 7.95318Z" fill="#B4B4FA" />
                <path d="M12.0723 7.47852C11.9692 7.47852 11.8664 7.54619 11.7881 7.68145L10.2004 10.4318C10.0538 10.1774 9.51937 9.25217 9.51937 9.25217C9.44129 9.11691 9.33844 9.04933 9.2355 9.04933C9.13265 9.04933 9.02971 9.11691 8.95163 9.25217C8.95163 9.25217 8.30148 10.3784 8.14505 10.6491L7.90648 11.0626C7.75041 11.3331 7.87793 11.5539 8.19044 11.5539H14.0243C14.3363 11.5539 14.4638 11.3331 14.3084 11.0626L12.356 7.68145C12.2781 7.54619 12.1754 7.47852 12.0723 7.47852Z" fill="#B4B4FA" />
            </g>
            <g clipPath="url(#clip1_2022_8678)">
                <path d="M3.62061 14.4955V6.24548" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.73657 6.83473L3.6205 5.95081L4.50443 6.83473" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.50439 15.3794H12.4598" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.8706 14.4955L12.7545 15.3794L11.8706 16.2633" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.62057 15.674C3.7833 15.674 3.91521 15.5421 3.91521 15.3794C3.91521 15.2166 3.7833 15.0847 3.62057 15.0847C3.45784 15.0847 3.32593 15.2166 3.32593 15.3794C3.32593 15.5421 3.45784 15.674 3.62057 15.674Z" fill="#B4B4FA" />
            </g>
            <defs>
                <clipPath id="clip0_2022_8678">
                    <rect width="11.7857" height="11.7857" fill="white" transform="translate(5.21436 3.07141)" />
                </clipPath>
                <clipPath id="clip1_2022_8678">
                    <rect width="11.7857" height="11.7857" fill="white" transform="translate(2 5.21423)" />
                </clipPath>
            </defs>
        </svg>


    );
}

export default SvgOneIcon;

