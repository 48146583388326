import * as React from "react";
import "../SignUp/SignUp.css";
import "./Profile.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useEffect } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getFileUrl, getUploadToken, updateUserImageUrl } from "../../actions";

const DEFAULT_DATA = {
  bio_descriptions: "",
  website_url: "",
  university_email: "",
  field_of_study: "",
  profession: "",
  linkedin_url: "",
  twitter_url: "",
  mstdn_url: "",
  gitlab_url: "",
  gitHub_url: "",
  youtube_url: "",
  instagram_handle: "",
  orc_id: "",
  user_img: "",

  email: '',
  city: '',
  postalCode: '',
  region: '',
  country: '',
  streetAddressOne: '',
  streetAddressTwo: '',
  affiliateOnBios: false,
  signUpOnBios: false,
  username:"",
};

export default function ProfileInformation(props) {
  const { openDialog, handleCloseDialog, onSubmit } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [isBiosPlatform, setIsBiosPlatform] = React.useState(false);
  const [data, setData] = React.useState(DEFAULT_DATA);
  const [isAffiliateBios, setIsAffiliateBios] = React.useState(false);
  const { userProfile } = useSelector((state) => state.bios);

  const handleClose = () => {
    setOpen(false);
    handleCloseDialog(false);
  };

  const handleChangeTerms = () => {
    setIsBiosPlatform((prevState) => !prevState);
  };
  const handleChangeAffiliate = () => {
    setIsAffiliateBios((prevState) => !prevState);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleChange = (e, key) => {
    setData({ ...data, [key]: e.target.value });
  };

  const handleClickSignUp = () => {
    onSubmit(data);
  };

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          async () => {
            setData({ ...data, user_img: file_name });
            const response = await getFileUrl(file_name);
            dispatch(updateUserImageUrl(response.data));
          },
          (err) => { },
          file
        )
      );
    }
  };

  useEffect(() => {
    if (userProfile) {
      setData(userProfile);
    }
  }, [userProfile]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="signUp-dialog"
        classes={{
          paper: "signUp-dialog",
        }}
        scroll="body"
      >
        <div className="dialog-content">
          <DialogContent dividers={true}>
            <DialogContentText>
              <Typography variant="h3" className="title-signup">
                Profile Information
              </Typography>
              <Typography variant="h4" className="sub-title-signup">
                Build your profile
              </Typography>
            </DialogContentText>
            <div className="signup-form">
              <input
                value={data.first_name}
                type="text"
                id="userName"
                className="input-custom"
                placeholder="Username"
                onChange={(e) => handleChange(e, "userName")}
              />
              <textarea
                value={data.bio_descriptions}
                type="text"
                id="bio_descriptions"
                rows="3"
                cols={4}
                className="input-custom-textarea"
                placeholder="Input your bio"
                onChange={(e) => handleChange(e, "bio_descriptions")}
              />
              <label htmlFor="imageUpload">
                Upload Profile Image
                <UploadIcon
                  sx={{
                    fontSize: "14px",
                    margin: "0px 4px -2px 4px",
                    color: "#505083",
                  }}
                />
              </label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                onChange={handleSelectImage}
              />
              <input
                value={data.website_url}
                type="text"
                id="website_url"
                className="input-custom"
                placeholder="Personal Site"
                onChange={(e) => handleChange(e, "website_url")}
              />
              <input
                value={data.university_email}
                type="text"
                id="university_email"
                className="input-custom"
                placeholder="University Email"
                onChange={(e) => handleChange(e, "university_email")}
              />
              <input
                value={data.field_of_study}
                type="text"
                id="field_of_study"
                className="input-custom"
                title="Input Field of Study(s) (must have LinkedIn, University email, or DOI to a publication where you are a contributor.)"
                placeholder="Input Field of Study(s) (must have LinkedIn, University email, or DOI to a publication where you are a contributor.)*"
                onChange={(e) => handleChange(e, "field_of_study")}
              />
              <input
                type="text"
                id="doi"
                className="input-custom"
                placeholder="DOI"
              />
              <input
                value={data.profession}
                type="text"
                id="profession"
                className="input-custom"
                placeholder="Profession"
                onChange={(e) => handleChange(e, "profession")}
              />
              <input
                value={data.linkedin_url}
                type="text"
                id="linkedin_url"
                className="input-custom"
                placeholder="LinkedIn URL"
                onChange={(e) => handleChange(e, "linkedin_url")}
              />
              <input
                value={data.orc_id}
                type="text"
                id="orc_id"
                className="input-custom"
                placeholder="OrcID"
                onChange={(e) => handleChange(e, "orc_id")}
              />
              <input
                value={data.twitter_url}
                type="text"
                id="twitter_url"
                className="input-custom"
                placeholder="Twitter Url"
                onChange={(e) => handleChange(e, "twitter_url")}
              />
              <input
                value={data.youtube_url}
                type="text"
                id="youtube_url"
                className="input-custom"
                placeholder="Youtube Url"
                onChange={(e) => handleChange(e, "youtube_url")}
              />
              <input
                value={data.mstdn_url}
                type="text"
                id="mstdn_url"
                className="input-custom"
                placeholder="Mastodon URL"
                onChange={(e) => handleChange(e, "mstdn_url")}
              />
              <input
                value={data.gitlab_url}
                type="text"
                id="gitlab_url"
                className="input-custom"
                placeholder="GitHub"
                onChange={(e) => handleChange(e, "gitlab_url")}
              />
              <input
                value={data.gitHub_url}
                type="text"
                id="gitHub_url"
                className="input-custom"
                placeholder="GitLab"
                onChange={(e) => handleChange(e, "gitHub_url")}
              />
              <input
                value={data.instagram_handle}
                type="text"
                id="instagram_handle"
                className="input-custom"
                placeholder="Enter instagram handle"
                onChange={(e) => handleChange(e, "instagram_handle")}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBiosPlatform}
                    onChange={handleChangeTerms}
                    name="isBiosPlatform"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 18 },
                      color: "#505083",
                      marginTop: "5px",
                      "&.Mui-checked": {
                        color: "#E1E6F2",
                      },
                    }}
                  />
                }
                label="I would not like to sign up to sell on the BIOS Platform"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    marginTop: "5px",
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAffiliateBios}
                    onChange={handleChangeAffiliate}
                    name="isAffiliateBios"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 18 },
                      color: "#505083",
                      marginTop: "-8px",
                      "&.Mui-checked": {
                        color: "#E1E6F2",
                      },
                    }}
                  />
                }
                label="I would like to be an affiliate of BIOS"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    marginTop: "-8px",
                  },
                }}
              />

              {isAffiliateBios && (
                <>
                  <input
                    type="text"
                    id="email-profile"
                    className="input-custom"
                    placeholder="Email"
                    onChange={(e) => handleChange(e, "email")}
                  />
                  <input
                    type="text"
                    id="streetAdd1"
                    className="input-custom"
                    placeholder="Street Address"
                    onChange={(e) => handleChange(e, "streetAddressOne")}
                  />
                  <input
                    type="text"
                    id="streetAdd12"
                    className="input-custom"
                    placeholder="Street Address 2"
                    onChange={(e) => handleChange(e, "streetAddressTwo")}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <input
                        type="text"
                        id="city"
                        className="input-custom"
                        placeholder="City"
                        onChange={(e) => handleChange(e, "city")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        type="text"
                        id="region"
                        className="input-custom"
                        placeholder="Region/State/Province"
                        onChange={(e) => handleChange(e, "region")}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                      <input
                        type="text"
                        id="postalCode"
                        className="input-custom"
                        placeholder="Postal/Zip Code"
                        onChange={(e) => handleChange(e, "postalCode")}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                      <input
                        type="text"
                        id="country"
                        className="input-custom"
                        placeholder="Country"
                        onChange={(e) => handleChange(e, "country")}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
            <Button
              variant="contained"
              className="contained-button-profile"
              onClick={handleClickSignUp}
            >
              Continue to Profile
            </Button>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
