

import React from "react"

function FilterTypeThree() {
    return (
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9637 25H0.267094C0.119391 25 0 24.8785 0 24.7283V0.271739C0 0.121467 0.119391 0 0.267094 0H18.9637C19.1114 0 19.2308 0.121467 19.2308 0.271739V24.7283C19.2308 24.8785 19.1111 25 18.9637 25ZM0.534188 24.4565H18.6966V0.543478H0.534188V24.4565Z" fill="#B4B4FA" />
            <path d="M17.0728 2.16979H2.15752C2.02776 2.16979 1.92285 2.11459 1.92285 2.04632C1.92285 1.97805 2.02776 1.92285 2.15752 1.92285H17.0728C17.2026 1.92285 17.3075 1.97805 17.3075 2.04632C17.3075 2.11459 17.2021 2.16979 17.0728 2.16979Z" fill="#B4B4FA" />
            <path d="M17.0728 6.01622H2.15752C2.02776 6.01622 1.92285 5.96103 1.92285 5.89276C1.92285 5.82448 2.02776 5.76929 2.15752 5.76929H17.0728C17.2026 5.76929 17.3075 5.82448 17.3075 5.89276C17.3075 5.96103 17.2021 6.01622 17.0728 6.01622Z" fill="#B4B4FA" />
            <path d="M17.0728 9.86229H2.15752C2.02776 9.86229 1.92285 9.8071 1.92285 9.73882C1.92285 9.67055 2.02776 9.61536 2.15752 9.61536H17.0728C17.2026 9.61536 17.3075 9.67055 17.3075 9.73882C17.3075 9.8071 17.2021 9.86229 17.0728 9.86229Z" fill="#B4B4FA" />
            <path d="M17.0728 13.7084H2.15752C2.02776 13.7084 1.92285 13.6532 1.92285 13.5849C1.92285 13.5166 2.02776 13.4614 2.15752 13.4614H17.0728C17.2026 13.4614 17.3075 13.5166 17.3075 13.5849C17.3075 13.6532 17.2021 13.7084 17.0728 13.7084Z" fill="#B4B4FA" />
            <path d="M17.0728 17.5549H2.15752C2.02776 17.5549 1.92285 17.4997 1.92285 17.4315C1.92285 17.3632 2.02776 17.308 2.15752 17.308H17.0728C17.2026 17.308 17.3075 17.3632 17.3075 17.4315C17.3075 17.4997 17.2021 17.5549 17.0728 17.5549Z" fill="#B4B4FA" />
            <path d="M17.1022 3.13182H4.05128C3.93774 3.13182 3.84595 3.07663 3.84595 3.00836C3.84595 2.94008 3.93774 2.88489 4.05128 2.88489H17.1022C17.2157 2.88489 17.3075 2.94008 17.3075 3.00836C17.3075 3.07663 17.2153 3.13182 17.1022 3.13182Z" fill="#B4B4FA" />
            <path d="M17.1022 6.97741H4.05128C3.93774 6.97741 3.84595 6.92221 3.84595 6.85394C3.84595 6.78566 3.93774 6.73047 4.05128 6.73047H17.1022C17.2157 6.73047 17.3075 6.78566 17.3075 6.85394C17.3075 6.92221 17.2153 6.97741 17.1022 6.97741Z" fill="#B4B4FA" />
            <path d="M17.1022 10.824H4.05128C3.93774 10.824 3.84595 10.7688 3.84595 10.7005C3.84595 10.6322 3.93774 10.577 4.05128 10.577H17.1022C17.2157 10.577 17.3075 10.6322 17.3075 10.7005C17.3075 10.7688 17.2153 10.824 17.1022 10.824Z" fill="#B4B4FA" />
            <path d="M17.1022 14.67H4.05128C3.93774 14.67 3.84595 14.6148 3.84595 14.5466C3.84595 14.4783 3.93774 14.4231 4.05128 14.4231H17.1022C17.2157 14.4231 17.3075 14.4783 17.3075 14.5466C17.3075 14.6148 17.2153 14.67 17.1022 14.67Z" fill="#B4B4FA" />
            <path d="M17.1022 18.5161H4.05128C3.93774 18.5161 3.84595 18.4609 3.84595 18.3926C3.84595 18.3244 3.93774 18.2692 4.05128 18.2692H17.1022C17.2157 18.2692 17.3075 18.3244 17.3075 18.3926C17.3075 18.4609 17.2153 18.5161 17.1022 18.5161Z" fill="#B4B4FA" />
            <path d="M17.1022 4.09301H4.05128C3.93774 4.09301 3.84595 4.03781 3.84595 3.96954C3.84595 3.90126 3.93774 3.84607 4.05128 3.84607H17.1022C17.2157 3.84607 17.3075 3.90126 17.3075 3.96954C17.3075 4.03781 17.2153 4.09301 17.1022 4.09301Z" fill="#B4B4FA" />
            <path d="M17.1022 7.93944H4.05128C3.93774 7.93944 3.84595 7.88425 3.84595 7.81597C3.84595 7.7477 3.93774 7.6925 4.05128 7.6925H17.1022C17.2157 7.6925 17.3075 7.7477 17.3075 7.81597C17.3075 7.88425 17.2153 7.93944 17.1022 7.93944Z" fill="#B4B4FA" />
            <path d="M17.1022 11.7851H4.05128C3.93774 11.7851 3.84595 11.73 3.84595 11.6617C3.84595 11.5934 3.93774 11.5382 4.05128 11.5382H17.1022C17.2157 11.5382 17.3075 11.5934 17.3075 11.6617C17.3075 11.73 17.2153 11.7851 17.1022 11.7851Z" fill="#B4B4FA" />
            <path d="M17.1022 15.6316H4.05128C3.93774 15.6316 3.84595 15.5764 3.84595 15.5081C3.84595 15.4398 3.93774 15.3846 4.05128 15.3846H17.1022C17.2157 15.3846 17.3075 15.4398 17.3075 15.5081C17.3075 15.5764 17.2153 15.6316 17.1022 15.6316Z" fill="#B4B4FA" />
            <path d="M17.1022 19.4776H4.05128C3.93774 19.4776 3.84595 19.4225 3.84595 19.3542C3.84595 19.2859 3.93774 19.2307 4.05128 19.2307H17.1022C17.2157 19.2307 17.3075 19.2859 17.3075 19.3542C17.3075 19.4225 17.2153 19.4776 17.1022 19.4776Z" fill="#B4B4FA" />
        </svg>



    );
}

export default FilterTypeThree;