// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .media-info {
    margin-top: 20px;
  }
  
  .media-info button {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .media-preview {
    /* margin-top: 20px; */
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .media-preview button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .media-preview img, .media-preview video {
    max-width: 100%;
    max-height: 400px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/MyAccount/UploadMedia.css"],"names":[],"mappings":";EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB","sourcesContent":["\n  .media-info {\n    margin-top: 20px;\n  }\n  \n  .media-info button {\n    padding: 10px 20px;\n    cursor: pointer;\n  }\n  \n  .media-preview {\n    /* margin-top: 20px; */\n    position: relative;\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n  \n  .media-preview button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    padding: 5px 10px;\n    cursor: pointer;\n  }\n  \n  .media-preview img, .media-preview video {\n    max-width: 100%;\n    max-height: 400px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
