import { string, object, array, boolean } from "yup";

// Define the schema for the nested parent/child object
const generalSpecSchema = object().shape({
  worldScale: string().required(),
  length: string().required(),
  width: string().required(),
  height: string().required(),
  vertices: string().required(),
  edges: string().required(),
  faces: string().required(),
  triangles: string().required(),
  quad: string().required(),
  polygons: string().required(),
});

// Define the schema for the nested parent/child object
const scientificDataAndCitationSchema = object().shape({
  scientificData: array().min(1),
  isVisible: boolean().required(),
});

// Define the schema for the nested parent/child object
const sceneInfoSchema = object().shape({
  description: string().required(),
  materials: array().min(1),
  textures: array().min(1),
});

// Define the schema for the nested parent/child object
const aIUsedInfoSchema = object().shape({
  isAiUsed: boolean().required(),
  aiUsedOption: string().required(),
  name: string().required(),
  description: string().required(),
});

// Define the schema for the nested child object
const modelInfoSchema = object().shape({
  generalSpec: generalSpecSchema.required(),
  scientificDataType: array().min(1),
  scientificDataAndCitation: scientificDataAndCitationSchema.required(),
  modelName: string().required(),
  scientificName: string().required(),
  scientificCollaboration: string().required(),
  sceneInfo: sceneInfoSchema.required(),
  hardwareRequirementDescription: string().required(),
  aIUsedInfo: aIUsedInfoSchema.required(),
  sceneDescription: string().required(),
  licenseInfo: string().required(),
  isViewParty: string().required(),
  availability: string().required(),
});

// Define the schema for the nested child object
const scientificInfoSchema = object().shape({
  primaryLayer: string().required(),
  primaryValue: string().required(),
  vocabulary: array().min(1),
  isPublished: boolean().required(),
  publishedInfo: array().min(1),
});

//Define the parent schema
export const createModelSchema = object().shape({
  modelInfo: modelInfoSchema.required(),
  scientificInfo: scientificInfoSchema.required(),
  models: array().min(1),
  field_icon_view: array().min(1)
});
