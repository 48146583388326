export const uploadModalObject = {
  isPublished: false,
  isApproved: false,
  models: [],
  modelInfo: {
    generalSpec: {
      worldScale: "",
      unit: "m",
      isOrganized: false,
      pbr: false,
      length: "",
      width: "",
      height: "",
      vertices: "",
      edges: "",
      faces: "",
      triangles: "",
      quad: "",
      polygons: "",
    },
    scientificDataType: [],
    scientificDataAndCitation: {
      isVisible: true,
      scientificData: [
        {
          url: "",
          dataFiles: [],
        },
      ],
    },
    modelName: "",
    scientificName: "",
    scientificCollaboration: "",
    collaborators: [
      {
        name: "",
        email: "",
        url: "",
        role: "",
        description: "",
        isCollaboration: false,
      },
    ],
    sceneInfo: {
      description: "",
      isUvMapping: false,
      UvMapValue: "",
      dataFiles: [],
      materials: [
        {
          name: "",
          description: "",
          resolution: {
            width: 0,
            height: 0,
          },
          dataFiles: [],
        },
      ],
      textures: [
        {
          name: "",
          description: "",
          resolution: {
            width: 0,
            height: 0,
          },
          dataFiles: [],
        },
      ],
    },
    hardwareRequirementDescription: "",
    aIUsedInfo: {
      isAiUsed: false,
      aiUsedOption: "",
      name: "",
      description: "",
      dataFiles: [],
    },
    riggingInfo: {
      isRigging: false,
      description: "",
      dataFiles: [],
    },
    animationInfo: {
      isAnimated: false,
      animations: [
        {
          name: "",
          fps: 0,
          totalFrames: 0,
          duration: 0,
          description: "",
          dataFiles: [],
        },
      ],
    },
    sceneDescription: "",
    licenseInfo: [],
    isViewParty: "public",
    download: true,
    ageInfo: {
      years: 0,
      months: 0,
    },
    availability: "free",
    price: 0,
    isIntegrationWithPlatform: "yes",
    productPreview: [],
  },
  scientificInfo: {
    primaryLayer: "",
    primaryValue: "",
    secondaryLayer: [],
    secondaryValue: [],
    vocabulary: [],
    isPublished: false,
    publishedInfo: [
      {
        name: "",
        title: "",
        doi: "",
      },
    ],
    referenceDatabases: [],
    methodologyDescription: "",
  },
  field_icon_view: []
};
