import React, { useCallback, useEffect, useMemo } from "react";
import "./MyAccount.css";
import {
  Typography,
  Avatar,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import MyAccountHeader from "./header";
import { useDispatch, useSelector } from "react-redux";
import WebsiteLinkIcon from "../../assets/svg/website_link";
import { GitHub } from "@mui/icons-material";
import GitlabIcon from "../../assets/svg/gitlab";
import OrcidIcon from "../../assets/svg/orcidIcon";
import TwitterIcon from "../../assets/svg/x-twitter";
import MstdnIcon from "../../assets/svg/mstdn";
import InstagramIcon from "../../assets/svg/instagram";
import LinkedinIcon from "../../assets/svg/linkdin";
import Card from "../../components/Card";
import UploadDialog from "../../components/UploadModel";
import { getUploadedModels, handleChangeDialogState, submitProfile, updateProfile } from "../../actions";
import { useQuery } from "react-query";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { debounce } from "lodash";
import EditImg from "../../assets/images/edit.png";
import UploadMedia from "./UploadMedia";
import DeleteMedia from "./DeleteMedia";

// function stringToColor(string) {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = "#";

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// }

function stringAvatar(name, width = 60, height = 60, fontSize = 24) {
  return {
    sx: {
      // bgcolor: stringToColor(name),
      width: width,
      height: height,
      marginRight: "5px",
      fontSize: `${fontSize}px`,
    },
    // children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const styles = {
  color: "rgb(254 238 205 / 25%)",
  textTransform: "capitalize",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "22px",
  "&.Mui-selected": {
    color: "#feeecd",
  },
};

const MyAccount = () => {
  const {
    user,
    userProfile,
    user_image_url = "",
    uploadedModelsState = [],
    favModelsState = [],
  } = useSelector((state) => state.bios);
  // const fullName = `${user?.first_name || "x"} ${user?.last_name || "x"}`;
  const [value, setValue] = React.useState("upload");
  const [isYoutube, setIsYoutube] = React.useState(true);
  const [youtubeLink, setYoutubeLink] = React.useState(userProfile && userProfile?.media_showcase_urlembed);
  const [media, setMedia] = React.useState(null);

  const isMediaImported = useMemo(() => {
    return youtubeLink || media
  }, [media, youtubeLink]);

  const [uploadModal, setUploadModal] = React.useState(false);
  const [selectedModelId, setSelectedModelId] = React.useState(undefined);

  const dispatch = useDispatch();

  // useEffect(async () => {
  //   if (userProfile && userProfile.media_showcase_upload) {
  //     console.log("userProfile.media_showcase_upload ", userProfile.media_showcase_upload)
  //     // console.log("userProfile.media_showcase_upload 00", await userProfile.media_showcase_upload.json())
  //     // Debugging: Log FormData content

  //     // for (let key of userProfile.media_showcase_upload.keys()) {
  //     //   console.log("key ===", userProfile.media_showcase_upload.get(key));
  //     // }
  //   }

  // }, [userProfile]);

  const onUploudMedia = useCallback(async (file) => {

    setMedia(file);
    console.log("formData formData file", file)


    const formData = new FormData();
    formData.append('file', file);

    console.log("formData formData", formData)

    // // Debugging: Log FormData content
    // for (let key of formData.keys()) {
    //   console.log(key, formData.get(key));
    // }

    // try {
    //   const response = await fetch('YOUR_POST_API_ENDPOINT', {
    //     method: 'POST',
    //     body: formData,
    //   });
    //   await dispatch(submitProfile({ ...userProfile, media_showcase_upload: formData }, () => {
    //     console.log("updated")
    //   }))

    // } catch (error) {
    //   // setUploadStatus(`Error: ${error.message}`);
    // }

  }, []);
  console.log("userProfile", userProfile)

  const getAllModelsData = useQuery(
    "getAllModels",
    () => getUploadedModels(dispatch),
    {
      retry: false,
      // refetchOnWindowFocus: false,
    }
  );

  const handleOpenUpload = () => {
    setUploadModal(true);
  };

  const handleCloseUpload = () => {
    setUploadModal(false);
    console.log("rrrrrrrrrrr")
    getAllModelsData.refetch();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectImage = async (event) => {
    const file = event.target.files[0];
    if (file) {

      const formData = new FormData();
      formData.append('file', file);

      console.log("formData formData", formData)


      try {
        await dispatch(submitProfile({ ...userProfile, media_showcase_upload: formData }, () => {
          console.log("updated")
        }))

      } catch (error) {
        // setUploadStatus(`Error: ${error.message}`);
      }





      // dispatch(
      // );
    }
  };


  const getYouTubeID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)?([^&\n]+)|youtu\.be\/([^&\n]+)/;
    const match = url.match(regex);
    return match ? (match[1] || match[2]) : null;
  };


  const handleOpenDelete = useCallback((cardId) => {
    setSelectedModelId(cardId);
  }, []);

  const handleCloseDelete = () => {
    setSelectedModelId(null);
    getAllModelsData.refetch();
  };

  const onDeleteCard = useCallback((cardId) => {
    setSelectedModelId(cardId);
  }, []);


  return (
    <>
      <div className="background-light">
        <MyAccountHeader />
        <div className="account-container">
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12} style={{ paddingLeft: "38px", paddingRight: "0px" }}>
              <Typography variant="h3" className="acc-title">
                My Account
              </Typography>
              <div className="avatar-cnt">
                <Avatar
                  {...stringAvatar()}
                  src={user_image_url ?? ""}
                  alt="User-Image"
                />
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", paddingInline: "16px" }}>
                  <p className="user_name">{`${user?.last_name || ""}`}</p>
                  <p className="user_title">{userProfile?.profession ?? "-"}</p>
                  <p
                    className="edit_profile_title"
                    onClick={() => {
                      dispatch(
                        handleChangeDialogState({
                          profileDialog: true,
                        })
                      );
                    }}
                  >
                    <img alt="EditImg" style={{ width: "18px" }} src={EditImg} />

                    {/* <BorderColorIcon /> */}
                    {/* Edit profile */}
                  </p>
                </div>
              </div>
              <div className="btn-cnt">
                <div>
                  <Typography variant="p" className="study">
                    Field of study
                  </Typography>
                  <Typography variant="p" className="micro">
                    {userProfile?.field_of_study ?? ""}
                  </Typography>
                </div>
              </div>
              <p className="paragraph-three">
                {userProfile?.bio_descriptions ?? "-"}
              </p>
              <div className="coments-sec">

              </div>
              <div className="icons-cnt">
                <div>
                  <Typography variant="h6" className="website-link">
                    {" "}
                    <WebsiteLinkIcon /> {userProfile?.website_url ?? ""}
                  </Typography>
                </div>
                <a
                  href={userProfile?.gitHub_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GitHub />
                </a>
                <a
                  href={userProfile?.gitlab_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GitlabIcon />
                </a>
                <OrcidIcon />
                <a
                  href={userProfile?.twitter_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href={userProfile?.mstdn_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <MstdnIcon />
                </a>
                <a
                  href={userProfile?.instagram_handle}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href={userProfile?.linkedin_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedinIcon />
                </a>
              </div>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }} >
                <p className="showcase-title">Showcase</p>
                {isMediaImported && <div style={{ display: "flex", justifyContent: "center", position: "absolute", right: "32px" }} >
                  <p style={{ marginInline: "8px", color: "#282845", cursor: "pointer" }} onClick={() => {
                    dispatch(submitProfile({ ...userProfile, media_showcase_urlembed: "", media_showcase_upload: {} }, () => {
                      setYoutubeLink("");
                      setMedia(null);
                    }));
                  }} >Delete</p>
                </div>}
              </div>

              <div className="showcase">
                <div className="showcase-cnt" style={{ position: "relative" }}>
                  {!isMediaImported && <div style={{ marginTop: "16px" }}>
                    <label
                      className='btnSwitch'
                      style={{ backgroundColor: isYoutube && "#1b1c30" }}
                      // htmlFor="imageUpload"
                      onClick={() => {
                        setIsYoutube(true)
                      }}
                    >
                      Youtube
                    </label>
                    <label
                      className="btnSwitch"
                      style={{ backgroundColor: !isYoutube && "#1b1c30" }}
                      // htmlFor="imageUpload"
                      onClick={() => {
                        setIsYoutube(false)
                      }}
                    >
                      Upload
                    </label>
                  </div>}

                  {
                    isYoutube ?
                      <div className="signup-form" style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        {
                          youtubeLink ?
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", paddingInline: "8px" }}>
                              <iframe
                                className='video'
                                style={{ width: "100%", height: "100%", marginBottom: "8px", borderRadius: "24px" }}
                                title='Youtube player'
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://youtube.com/embed/${youtubeLink}?autoplay=0`}>
                              </iframe>
                              {/* <CloseIcon style={{ marginInline: "8px", color: "white", cursor: "pointer" }} onClick={() => {
                                dispatch(submitProfile({ ...userProfile, media_showcase_urlembed: "" }, () => {
                                  setYoutubeLink("")
                                }));
                              }} /> */}
                            </div>
                            :
                            <>
                              <input
                                type="text"
                                id="youtubeLink"
                                className="input-custom"
                                placeholder="Youtube Link"
                                style={{ width: "300px" }}
                                // defaultValue={userProfile.media_showcase_urlembed}
                                onChange={(e) => {
                                  console.log("ee", e)
                                  // const link = e.target.value;
                                  const id = getYouTubeID(e.target.value);
                                  setYoutubeLink(id);
                                  // debounce(() => {
                                  dispatch(submitProfile({ ...userProfile, media_showcase_urlembed: id }, () => {
                                    console.log("updated")
                                  }));

                                  // }, 500);
                                }}
                              />
                              <Typography variant="h4" className="upload-content-des">
                                Please insert your youtube Link
                              </Typography>
                            </>
                        }
                      </div>
                      :
                      <>
                        <UploadMedia media={media} setMedia={onUploudMedia} />
                        {/* <label className="upload-content" htmlFor="imageUpload" style={{ marginTop: "72px" }}>
                          Link to your media
                          <UploadIcon
                            sx={{
                              fontSize: "14px",
                              margin: "0px 4px -2px 4px",
                              color: "#505083",
                            }}
                          />
                        </label>
                        <Typography variant="h4" className="upload-content-des">
                          Input links to JoVE, Youtube, other video or images
                        </Typography>
                        <input
                          type="file"
                          id="imageUpload"
                          accept="image/*,video/*"
                          onChange={handleSelectImage}
                        /> */}
                      </>
                  }



                  {/* <Typography variant="h3" className="upload-content">
                    Link to your media
                  </Typography>
                  <Typography variant="h4" className="upload-content-des">
                    Input links to JoVE, Youtube, other video or images
                  </Typography> */}
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="uploaded-info-container">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="transparent"
                sx={{
                  "&.Mui-selected": {
                    color: "transparent",
                  },
                }}
              >
                <Tab
                  value="liked"
                  label={`Liked ${favModelsState.length}`}
                  sx={styles}
                />
                <Tab value="collection" label={`Collection ${0}`} sx={styles} />
                <Tab
                  value="upload"
                  label={`Uploads ${uploadedModelsState?.length}`}
                  sx={styles}
                />
              </Tabs>
              <Button
                variant="contained"
                className="upload-model-button"
                onClick={handleOpenUpload}
              >
                Upload Model
              </Button>
            </Box>

            {value === "liked" && (
              <Box>
                <Grid container spacing={2}>
                  {favModelsState.map((model, index) => {
                    return (
                      <Grid item md={3} key={index}>
                        <Card cardInfo={model} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
            {value === "collection" && <Box></Box>}
            {value === "upload" && (
              <Box>
                <Grid container spacing={2}>
                  {uploadedModelsState.map((model, index) => {
                    return (
                      <Grid item md={3} key={index}>
                        <Card cardInfo={model} onDelete={handleOpenDelete} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
          </div>
        </div>
      </div >
      {uploadModal && (
        <UploadDialog open={uploadModal} onClose={handleCloseUpload} />
      )}
      {selectedModelId && (
        <DeleteMedia open={selectedModelId} onClose={handleCloseDelete} />
      )}

    </>
  );
};

export default MyAccount;
