export const GraphData = [
  {
    name: "r",
    intensity: 800,
  },
  {
    name: "2sr",
    intensity: 400,
  },
  {
    name: "3sr",
    intensity: 200,
  },
  {
    name: "4sr",
    intensity: 100,
  },
  {
    name: "5sr",
    intensity: 50,
  },
  {
    name: "6sr",
    intensity: 25,
  },
  {
    name: "7sr",
    intensity: 0,
  },
];
