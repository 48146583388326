import React, { Fragment } from 'react';
import './Home.css'
import { Box, Button, ListItem, ListItemText, Typography } from '@mui/material';
import HeaderImage from "../../assets/images/header-image.png";
import EarthImage from "../../assets/images/earth.png";
import AccountImage from "../../assets/images/account-image.png";
import ContentImage from "../../assets/images/upload-content-img.png";
import ShareImage from "../../assets/images/share-image.png";
import EmpowerImage from "../../assets/images/empower-img.png";
import BIOSImage from "../../assets/images/bios.png";
import ArrowDown from "../../assets/images/arrow-down.png";
import CreationImage from "../../assets/images/creation-image.png";
import DashImage from "../../assets/images/dash.png";
import Header from '../../components/Header';
import { handleChangeDialogState } from '../../actions';
import { useDispatch } from 'react-redux';

const Home = () => {
  const dispatch = useDispatch();

  const handleOpenSignUp = () => {
    dispatch(handleChangeDialogState({
      signUpDialog: true
    }));
  };

  return (

    <div className='App'>
      <Header />
      <section>
        <div className='header-container'>
          <div>
            <Typography variant='h1' className='header-title'>
              A Platform for 3D Life Sciences
            </Typography>
            <p className='paragraph-one'>
              BIOS is a new platform for publishing immersive 3D models, connecting global scientific communities. It enhances user engagement, fosters collaboration, and transforms data communication, offering an innovative tool for science education. With its user-friendly interface and open accessibility, BIOS bridges the gap between research and a visually intuitive understanding of complex phenomena.
            </p>
            <p className='paragraph-one'>
              Unlocking the power of 3D models in science transforms intricate concepts into engaging visual experiences, making complex ideas accessible and fostering a deeper connection with the wonders of discovery, bridging the gap between scientific exploration and broader understanding.
            </p>
            <div className='take-look-container'>
              <p className='take-look-text'>
                Take a look!
              </p>
              <img src={EarthImage} alt="Earth Globe" width={'180px'} height={'158px'} className='globeImage' />
            </div>
          </div>
          <div>
            <img src={HeaderImage} alt="header" className='header-image' />
          </div>
        </div >
      </section>

      <section>
        <div style={{ marginTop: "48px" }}>
          <Typography variant='h1' className='header-title center'>
            Growing the science community
          </Typography>
          <div className='growth-container'>
            <img src={AccountImage} alt="Account" className='account-image' />
            <div className='account-des'>
              <Typography variant='p' className='growth-des'>The BIOS platform is made for scientists, science animators, & science communicators to post & host their 3D models.</Typography>
              <Typography variant='p' className='growth-des'>User models provide a 3D visual ontology for the scientific community & publications to reference. This adds a new dimension to science.</Typography>
            </div>
          </div>
        </div>
      </section>

      <section className='profile-creating-sec'>
        <div className='content-container'>
          <div>
            <Typography variant='p' className='profile-title'>
              Step 1: <span className='sub-head'>
                Profile Creation
              </span>
            </Typography>
            <p className='paragraph-two'>
              Click ‘Sign Up’ in the top right of the site to create a profile:
            </p>
            <Box component="ul" >
              <ListItem component="li" disablePadding >
                <ListItemText primary="Upload a profile pic" className='list-item-text' />
              </ListItem>
              <ListItem component="li" disablePadding>
                <ListItemText primary="Input your information" className='list-item-text' />
              </ListItem>
              <ListItem component="li" disablePadding>
                <ListItemText primary="Add your handle or URL to other media platforms!" className='list-item-text' />
              </ListItem>
            </Box>
          </div>
          <div className='creation-container'>
            <img src={CreationImage} alt="content" width={'300px'} height={'254px'} />
            <div className='create-acc-cnt'>
              <Typography variant='h3' className='sign-up-title'>Sign Up Here!</Typography>
              <Button variant="contained" className="create-button" onClick={handleOpenSignUp}>Create an Account</Button>
              <img src={DashImage} alt="content" width={'159px'} height={'95px'} style={{ marginTop: '-33px' }} />
              <img src={ArrowDown} alt="BIOS" width={'107px'} height={'123px'} className='arrow-left' />
            </div>
          </div>
        </div>
      </section>

      <section className='profile-creating-sec'>
        <div className='content-container'>
          <div>
            <Typography variant='p' className='profile-title'>
              Step 2: <span className='sub-head'>
                Upload Content
              </span>
            </Typography>
            <p className='paragraph-two'>
              On your profile page, select <span className='badge'>Upload Model</span>.
            </p>
            <Box component="ul" >
              <ListItem component="li" disablePadding >
                <ListItemText primary="Fill out the form (saves automatically)." className='list-item-text' />
              </ListItem>
              <ListItem component="li" disablePadding>
                <ListItemText primary={<>
                  Click
                  <span className='badge'>Publish</span>
                  to make your upload public.
                </>} className='list-item-text' />
              </ListItem>
              <ListItem component="li" disablePadding>
                <ListItemText primary="More data & info provided in your upload results in a higher model quality rating and increased likeliness of interactions & exposure." className='list-item-text' />
              </ListItem>
            </Box>
            <Typography variant='p' className='caution-title'>
              Caution: <span style={{ fontWeight: 300 }}>
                The larger the file, the longer the upload & the higher the likelihood of having to re-upload. We do our best to make this a non-issue.
              </span>
            </Typography>
          </div>
          <div>
            <img src={ContentImage} alt="content" className='creation-image' />
          </div>
        </div>
      </section>

      <section className='profile-creating-sec'>
        <div className='content-container'>
          <div>
            <Typography variant='p' className='profile-title'>
              Step 3: <span className='sub-head'>
                Share your content!
              </span>
            </Typography>
            <Box component="ul" >
              <ListItem component="li" disablePadding >
                <ListItemText primary="After email confirmation your model/animation will be available for everyone." className='list-item-text' />
              </ListItem>
              <ListItem component="li" disablePadding>
                <ListItemText primary="You may update your information on the model at any time & make changes." className='list-item-text' />
              </ListItem>
              <ListItem component="li" disablePadding>
                <ListItemText primary="Share your content everywhere by linking! Tweet, Instagram, Thread, video, post on substack, & cite it in publications!" className='list-item-text' />
              </ListItem>
            </Box>
            <Typography variant='p' className='caution-title'>
              <span style={{ fontWeight: 300 }}>
                If you have any questions, please reach out <a href="https://airtable.com/apptV3pBdFaUuyN3U/pag8dMkSzYa98DSht/form" className="caution-title" style={{ textDecoration: 'none' }}>here</a>.
              </span>
            </Typography>
          </div>
          <div>
            <img src={ShareImage} alt="content" className='creation-image' />
          </div>
        </div>
      </section>

      <section className='profile-creating-sec'>
        <div className='empower-container'>
          <img src={EmpowerImage} alt="Account" className='empower-image' style={{ paddingTop: '70px' }} />
          <div className='account-des'>
            <Typography variant='h3' className='empower-title'>
              Empowering the Science Community
            </Typography>
            <Typography variant='p' className='growth-des'>BIOS strives to give scientists more recognition & increase their social presence. To do this a citation field will display data about the concept, publication(s), authors w/images, discovery date & pub. date, organizations involved, & funding when available. This provides an increased transparency & recognition for the scientific community.</Typography>
          </div>
        </div>
      </section>

      <section className='bios-sec-container'>
        <div>
          <Typography variant='h1' className='header-title center'>
            Striving to Increase Education & Ideation
          </Typography>
          <p className='paragraph-sub'>
            By increasing the surface area to volume ratio of research & other scientific information to expand participation and higher level discourse will drive innovation & accelerate science.
          </p>
          <p className='paragraph-sub' style={{ maxWidth: '77%', paddingTop: '80px', textAlign: 'center' }}>
            BIOS aims to set a new standard for science communication and digital education for life sciences.
          </p>
        </div>
        <div className='bios-sec'>
          <img src={BIOSImage} alt="BIOS" className='bios-image' />
          <div>
            <Typography variant='h3' className='empower-title'>
              Sign up for the BIOS App
            </Typography>
            <Button variant="contained" className="contained-signUp-button" onClick={handleOpenSignUp}>Sign Up</Button>

          </div>
          <img src={ArrowDown} alt="BIOS" width={'107px'} height={'123px'} className='arrow-down' />

        </div>
      </section>
    </div>
  )

}

export default Home;
