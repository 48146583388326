import { CircularProgress } from "@mui/material";
import "./App.css";
import Routes from "./Routes";
import useInitialLoad from "./hooks/useInitialLoad";

function App() {
  const { isLoading } = useInitialLoad();

  return isLoading ? (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  ) : (
    <Routes />
  );
}

export default App;
