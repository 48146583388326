

import React from "react"

function GitlabIcon() {
    return (
        <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.167 10.9632L26.1314 10.8724L22.692 1.89605C22.622 1.72011 22.4981 1.57087 22.338 1.46973C22.1778 1.37031 21.991 1.32242 21.8028 1.33254C21.6146 1.34266 21.434 1.41029 21.2854 1.52631C21.1384 1.64566 21.0319 1.80738 20.9801 1.98947L18.6577 9.09473H9.2538L6.93143 1.98947C6.88106 1.80639 6.77424 1.64386 6.62617 1.52499C6.47759 1.40897 6.29699 1.34134 6.10875 1.33122C5.92051 1.32111 5.7337 1.36899 5.57354 1.46841C5.41383 1.56996 5.29003 1.71908 5.21959 1.89473L1.77354 10.8671L1.73933 10.9579C1.2442 12.2516 1.18308 13.6712 1.56519 15.0026C1.9473 16.3341 2.75193 17.5052 3.85775 18.3395L3.86959 18.3487L3.90117 18.371L9.14064 22.2947L11.7327 24.2566L13.3117 25.4487C13.4964 25.5889 13.7219 25.6648 13.9538 25.6648C14.1857 25.6648 14.4112 25.5889 14.5959 25.4487L16.1749 24.2566L18.767 22.2947L24.038 18.3474L24.0512 18.3368C25.1545 17.5024 25.9573 16.3325 26.3388 15.0028C26.7203 13.6732 26.66 12.2556 26.167 10.9632Z" fill="#1B1C30" />
            <path d="M26.167 10.9632L26.1314 10.8724C24.4555 11.2164 22.8762 11.9263 21.5064 12.9513L13.9525 18.6631C16.5248 20.6092 18.7643 22.3 18.7643 22.3L24.0354 18.3526L24.0485 18.3421C25.1534 17.5077 25.9574 16.337 26.3395 15.0062C26.7216 13.6755 26.661 12.2566 26.167 10.9632Z" fill="#282845" />
            <path d="M9.14064 22.3L11.7327 24.2618L13.3117 25.4539C13.4964 25.5942 13.7219 25.6701 13.9538 25.6701C14.1857 25.6701 14.4112 25.5942 14.5959 25.4539L16.1749 24.2618L18.767 22.3C18.767 22.3 16.5248 20.6039 13.9525 18.6631C11.3801 20.6039 9.14064 22.3 9.14064 22.3Z" fill="#282845" />
            <path d="M6.39722 12.9513C5.02852 11.9241 3.44959 11.2124 1.77354 10.8671L1.73933 10.9579C1.2442 12.2516 1.18308 13.6712 1.56519 15.0026C1.9473 16.3341 2.75193 17.5052 3.85775 18.3395L3.86959 18.3487L3.90117 18.371L9.14064 22.2947C9.14064 22.2947 11.3775 20.6039 13.9525 18.6579L6.39722 12.9513Z" fill="#282845" />
        </svg>

    );
}

export default GitlabIcon;