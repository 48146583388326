import React from 'react';
import { useTheme } from '@mui/material/styles';

const ThemeVariables = ({ children }) => {
    const theme = useTheme();

    const style = {
        "--primary-color": theme.palette.primary.main,
        "--white": theme.palette.primary.white,
        "--black": theme.palette.primary.black,
        "--bg-color": theme.palette.primary.bgColor,
        "--yellow": theme.palette.primary.yellow,
        "--secondary-color": theme.palette.secondary.main,
        "--dusty-purple-40": theme.palette.secondary.dustyPurple40,
        "--dusty-purple-20": theme.palette.secondary.dustyPurple20,
        "--dusty-purple-70": theme.palette.secondary.dustyPurple70,
        "--light-grey": theme.palette.secondary.lightgrey
        // Add more as needed
    };

    return <div style={style}>{children}</div>;
}

export default ThemeVariables;
