import React, { useState } from 'react';
import './UploadMedia.css';
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import {
    Typography,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';


function UploadMedia({ open, onClose }) {

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={false}
                maxWidth={'md'}
                aria-labelledby="responsive-dialog-title"
                className='signUp-dialog'
                classes={{
                    paper: 'signUp-dialog'
                }}
                scroll='paper'
                onClose={onClose}
            >
                <div className='dialog-content'>

                    <DialogContent dividers={true} style={{ background: "#1D203A" }}>
                        <DialogContentText style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "8px" }}>
                            <Typography variant='h3' className='title-signup'>Are you Sure ?</Typography>
                            <Typography variant='h4' className='sub-title' style={{ lineHeight: "24px", marginBottom: "24px", marginTop: "16px" }}>Selecting ‘delete’ means all content uploaded in this save wil be deleted from your account.</Typography>
                            <Typography variant='h4' className='sub-title' style={{ lineHeight: "24px", marginBottom: "24px", marginTop: "16px" }}>You may not reverse this.</Typography>
                        </DialogContentText>
                        <div className='signup-form'>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Button
                                variant="contained"
                                className="contained-button-delete"
                                // disabled={submitBtnEnabled}
                                // onClick={handleSubmitOtp}
                                style={{ marginTop: '10px' }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </DialogContent>
                </div>
            </Dialog >
        </div >
    );
}

export default UploadMedia;
