import React from "react";

function StericIcon() {
  return (
    <svg
      width="5"
      height="6"
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginBottom: '6px'
      }}
    >
      <path
        d="M4.61947 1.872L4.88547 2.362L2.86947 3.356L4.91347 4.35L4.63347 4.84L2.71547 3.664L2.81347 5.96H2.25347L2.35147 3.664L0.433469 4.854L0.139469 4.35L2.18347 3.356L0.167469 2.362L0.433469 1.872L2.35147 3.062L2.25347 0.78H2.81347L2.71547 3.062L4.61947 1.872Z"
        fill="#FF0000"
      />
    </svg>
  );
}

export default StericIcon;
