import React, { useState } from 'react';
import './UploadMedia.css';
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import {
    Typography,
} from "@mui/material";


function UploadMedia({ media, setMedia }) {
    const [preview, setPreview] = useState(null);

    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/png' || file.type.startsWith('video/'))) {
            setMedia(file);
            handlePreview(file);
        }
    };

    const handlePreview = (file) => {
        const url = URL.createObjectURL(file);
        setPreview({ url, type: file.type });
    };

    return (
        <>
            {!media &&
                <>
                    <label className="upload-content" htmlFor="imageUpload" style={{ marginTop: "72px" }}>
                        Link to your media
                        <UploadIcon
                            sx={{
                                fontSize: "14px",
                                margin: "0px 4px -2px 4px",
                                color: "#505083",
                            }}
                        />

                    </label>
                    <Typography variant="h4" className="upload-content-des">
                        Input links to JoVE, Youtube, other video or images
                    </Typography>
                    <input type="file" id="imageUpload" accept="image/png,video/*" onChange={handleUpload} />
                    {/* <h1>Media Uploader</h1> */}
                </>
            }
            {
                media && (
                    <div className="media-preview">
                        {/* <button onClick={() => setPreview(null)}>Close Preview</button> */}
                        {preview.type.startsWith('video/') ? (
                            <video controls style={{ objectFit: "contain", width: "100%", height: "100%" }}>
                                <source src={preview.url} type={preview.type} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={preview.url} alt="Preview" />
                        )}
                    </div>
                )
            }
        </>
    );
}

export default UploadMedia;
