export const tabs = [
    {
        label: 'Biosphere',
        key: 'biosphere',
        arrayIndex: 25,
    },
    {
        label: 'Biomes',
        key: 'biomes',
        arrayIndex: 2,
    },
    {
        label: 'Ecosystems',
        key: 'ecosystems',
        arrayIndex: 5,

    },
    {
        label: 'Communities',
        key: 'communities',
        arrayIndex: 3,

    },
    {
        label: 'Organisms',
        key: 'organisms',
        arrayIndex: 8,

    },
    {
        label: 'Organ systems',
        key: 'organSystems',
        arrayIndex: 2,

    },
    {
        label: 'Organs',
        key: 'organs',
        arrayIndex: 3,

    },
    {
        label: 'Tissues',
        key: 'tissues',
        arrayIndex: 4,

    },
    {
        label: 'Cells',
        key: 'cells',
        arrayIndex: 5,

    },
    {
        label: 'Biotechnology',
        key: 'biotechnology',
        arrayIndex: 2,

    },
    {
        label: 'Small Molecules',
        key: 'smallMolecules',
        arrayIndex: 1,

    },
    {
        label: 'Atoms & Chemistry',
        key: 'atomsChemistry',
        arrayIndex: 3,

    },
];

export const models = ["Wavefront (.OBJ)", "FBX", "DAE", "3DS", "gLTF", "GLB", "USD", "USDZ", "VRML"];
export const modelTypesList = ["Personal", "Academic", "Commercial", "Small Business", "Non-Profit"];
export const animatedList = ["Static Model Rigged", "Rigged & Animated", "Behavioral Animations", "Static Model Unrigged", "Game/Simulation Ready"];
