import axios from "axios";
import { BASE_URL } from "./config";
import jwtDecode from "jwt-decode"; // library for decoding JWTs

const api = axios.create({
  baseURL: BASE_URL,
});

const isTokenExpired = (token) => {
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // to get time in seconds
    return decoded.exp < currentTime;
  }

  return false;
};

// Interceptor for requests
api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    // Check if accessToken is expired
    if (isTokenExpired(token)) {
      try {
        // Refresh the token
        const response = await axios.post(`${BASE_URL}/refresh-jwt-token`, {
          refreshToken,
        });

        // Update token and refresh token in local storage
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        token = response.data.accessToken; // update the token variable for later use
      } catch (error) {
        console.error("Failed to refresh token");
        // Redirect to login or clear the refresh token
        // Refresh token is expired or something went wrong, sign-out and redirect to home
        localStorage.clear(); // or use your state management library to clear the tokens
        if (window.location.pathname !== "/") window.location.href = "/";
        // window.location.href = '/'; // Redirect to home page
        return Promise.reject(error);
      }
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
