





import React from "react"

function ImageAlbumIcon() {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 26.25C20.5416 26.25 26.2499 20.5416 26.2499 13.5C26.2499 6.45837 20.5416 0.75 13.5 0.75C6.45835 0.75 0.75 6.45837 0.75 13.5C0.75 20.5416 6.45835 26.25 13.5 26.25Z" stroke="#B4B4FA" stroke-width="0.5"/>
        <g clip-path="url(#clip0_3506_44)">
        <path d="M21.5118 18.2001H8.5637C7.62057 18.2001 6.85327 17.4346 6.85327 16.4931V7.63045C6.85327 6.6891 7.62057 5.92334 8.5637 5.92334H21.5118C22.455 5.92334 23.2223 6.6891 23.2223 7.63045V16.4929C23.2223 17.4344 22.455 18.2001 21.5118 18.2001ZM8.5637 6.94641C8.18466 6.94641 7.87633 7.2532 7.87633 7.63045V16.4931C7.87633 16.8701 8.18466 17.1771 8.5637 17.1771H21.5118C21.8909 17.1771 22.1992 16.8704 22.1992 16.4929V7.63045C22.1992 7.2532 21.8909 6.94641 21.5118 6.94641H8.5637Z" fill="#B4B4FA"/>
        <path d="M12.8306 10.6575C13.436 10.6575 13.9268 10.1667 13.9268 9.5613C13.9268 8.95588 13.436 8.46509 12.8306 8.46509C12.2252 8.46509 11.7344 8.95588 11.7344 9.5613C11.7344 10.1667 12.2252 10.6575 12.8306 10.6575Z" fill="#B4B4FA"/>
        <path d="M16.3781 9.99829C16.235 9.99829 16.0922 10.0923 15.9835 10.2801L13.7782 14.1C13.5747 13.7468 12.8324 12.4617 12.8324 12.4617C12.724 12.2738 12.5811 12.18 12.4382 12.18C12.2953 12.18 12.1523 12.2738 12.0439 12.4617C12.0439 12.4617 11.1409 14.026 10.9236 14.4018L10.5923 14.9761C10.3755 15.3519 10.5527 15.6585 10.9867 15.6585H19.0892C19.5226 15.6585 19.6997 15.3519 19.4839 14.9761L16.7722 10.2801C16.6641 10.0923 16.5213 9.99829 16.3781 9.99829Z" fill="#B4B4FA"/>
        </g>
        <path d="M4.63965 19.7437V8.2854" stroke="#B4B4FA" stroke-width="0.45" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.41187 9.1039L4.63954 7.87622L5.86722 9.1039" stroke="#B4B4FA" stroke-width="0.45" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.86719 20.9714H16.9163" stroke="#B4B4FA" stroke-width="0.45" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.0979 19.7439L17.3256 20.9716L16.0979 22.1993" stroke="#B4B4FA" stroke-width="0.45" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.63945 21.3807C4.86546 21.3807 5.04867 21.1975 5.04867 20.9715C5.04867 20.7455 4.86546 20.5623 4.63945 20.5623C4.41344 20.5623 4.23022 20.7455 4.23022 20.9715C4.23022 21.1975 4.41344 21.3807 4.63945 21.3807Z" fill="#B4B4FA"/>
        <defs>
        <clipPath id="clip0_3506_44">
        <rect width="16.369" height="16.369" fill="white" transform="translate(6.85327 3.8772)"/>
        </clipPath>
        </defs>
        </svg>
    );
}

export default ImageAlbumIcon;