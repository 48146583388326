import React, { useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Logo from "../../assets/images/logo.png";
import classNames from "classnames";
import { logout } from "../../actions";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import { ManageAccounts } from "@mui/icons-material";


const MyAccountHeader = () => {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const dispatch = useDispatch();
  const { user, user_image_url = "" } = useSelector((state) => state.bios);
  const [anchorEl, setAnchorEl] = useState(null);
  const accountBtn = useRef();
  const [accMenuOpen, setAccMenuOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="static" className="inAppHeader">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            {/* Logo on the left */}
            <Grid item>
              <img
                src={Logo}
                alt="logo"
                width={"125px"}
                height={"35px"}
                onClick={() => navigate("/")}
              />
            </Grid>

            {/* Centered items */}
            <Grid item xs>
              <Hidden smDown>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  {/* <Typography
                    onClick={() => navigate("/")}
                    variant="h6"
                    className={classNames("navBar-item", {
                      "navBar-item-disabled": pathName !== "/",
                    })}
                  >
                    Home Page
                  </Typography> */}
                  <Typography
                    variant="h6"
                    onClick={() => navigate("/platform")}
                    className={classNames("navBar-item", {
                      "navBar-item-disabled": pathName !== "/platform",
                    })}
                  >
                    Platform
                  </Typography>

                </div>
              </Hidden>
              <Hidden smUp>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "15px",
                  }}
                >
                  <IconButton color="inherit" onClick={handleMenu}>
                    <MenuIcon />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Typography
                      onClick={() => navigate("/")}
                      variant="h6"
                      className={classNames("navBar-item", {
                        "navBar-item-disabled": pathName !== "/",
                      })}
                    >
                      Home Page
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Typography
                      variant="h6"
                      className={classNames("navBar-item", {
                        "navBar-item-disabled": pathName !== "/platform",
                      })}
                    >
                      Platform
                    </Typography>
                  </MenuItem>

                </Menu>
              </Hidden>
            </Grid>

            {/* Login and Sign Up buttons on the right */}
            <Grid item>
              <div style={{ display: "flex", gap: "20px" }}>
                <div
                  style={{ display: "flex", alignItems: "center", flexDirection: "column-reverse" }}
                  onMouseOver={(ev) => {
                    accountBtn.current = ev.currentTarget;
                    setAccMenuOpen(true);
                  }}
                >
                  <Typography
                    className="user-title"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >{`${user?.first_name || ""}`}</Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}

                  // onClick={() => dispatch(logout())}
                  >
                    <Avatar src={user_image_url ?? ""} alt="User-Image" />
                  </div>
                </div>
                <Menu
                  anchorEl={accountBtn.current}
                  id="account-menu"
                  open={accMenuOpen}
                  onClose={() => setAccMenuOpen(false)}
                  transformOrigin={{ horizontal: "center", vertical: "bottom" }}
                  anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                  style={{ top: "58px" }}
                >
                  <MenuItem
                    onClick={() => {
                      setAccMenuOpen(false);
                      dispatch(logout());
                    }}
                  >
                    <ListItemIcon>
                      <ManageAccounts />
                    </ListItemIcon>
                    <Typography className="logout-title">Log out</Typography>
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MyAccountHeader;
