import React from "react";
import Header from "../../components/Header";
import "./Visualization.css";
import { Grid } from "@mui/material";
import { InlineMath, BlockMath } from "react-katex";
import "katex/dist/katex.min.css";
import Mercury from "../../assets/images/mercury.png";
import Venus from "../../assets/images/venus.png";
import Earth from "../../assets/images/earth.png";
import Moon from "../../assets/images/moon.png";
import MercuryLarge from "../../assets/images/mercury_large.png";
import VenusLarge from "../../assets/images/venus_large.png";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { GraphData } from "./constants";

const Visualization = () => {
  const rulerArray = Array.from({ length: 50 }, (_, index) => index + 1);
  return (
    <div className="App">
      <Header />
      <div className="visualization-page">
        <div className="visualization-page-title-container">
          <span className="visualization-page-title">
            Observe the effect of Mass and Distance on Gravitational Force!
          </span>
        </div>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <span className="title-chart">
              Gravitational Force Visualization
            </span>
            <div>
              <AreaChart
                width={600}
                height={400}
                data={GraphData}
                // margin={{
                //   top: 10,
                //   right: 30,
                //   left: 0,
                //   bottom: 0,
                // }}
              >
                {/* <CartesianGrid strokeDasharray="8 8" /> */}
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="intensity"
                  stroke="red"
                  fill="#fdbfbf"
                /> 
              </AreaChart>
            </div>
          </Grid>
          <Grid item sm={6}>
            <div className="formula-container">
              <div className="formula-cnt">
                <span className="title-chart">Inverse Square Law: </span>
                <BlockMath>{"f = G \\frac{m_1 m_2}{r^2}"}</BlockMath>
              </div>
              <div className="formula-cnt" style={{ alignItems: "normal" }}>
                <span className="title-chart">Gravitational Force:</span>
                <div className="explanation-cnt">
                  <span className="title-chart">
                    <InlineMath
                      math={
                        "G = 6.67430 \\times 10^{-11} \\text{m}^3 \\text{kg}^{-1} \\text{s}^{-2}"
                      }
                    />{" "}
                    <br />
                    The Gravitational constant
                  </span>
                  <span className="title-chart">
                    <InlineMath math="m_1" /> : Mass of one of the objects
                  </span>
                  <span className="title-chart">
                    <InlineMath math="m_2" /> : Mass of the other object
                  </span>
                  <span className="title-chart">
                    <InlineMath math="r^2" /> : Radius squared
                  </span>
                  <span className="title-chart">
                    <InlineMath math="f" /> : Gravitational Force
                  </span>
                </div>
              </div>
              <div className="units-container">
                <div className="units">
                  <span className="title-chart">Force: </span>
                  <span className="title-chart">000N•m </span>
                </div>
                <div className="units">
                  <span className="title-chart">Distance: </span>
                  <span className="title-chart">
                    000 x <InlineMath math="10^{6} {km}" />{" "}
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div>
          <div class="ruler">
            {rulerArray.map((item, index) => {
              return <div class="cm" style={{ left: `${index * 3}%` }}></div>;
            })}
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item sx={3}>
            <div className="planet-container">
              <span className="planet-title">Mercury</span>
              <div className="planer-cnt">
                <img src={Mercury} alt="mercury" />
              </div>
            </div>
            <div className="planet-info">
              <span>
                Mass: 0000 <InlineMath math="kg" />{" "}
              </span>
              <span>
                Distance: 0000 <InlineMath math="km" />
              </span>
              <span>
                Gravity: 0000 <InlineMath math="\text{m/s}^2" />
              </span>
            </div>
          </Grid>
          <Grid item sx={3}>
            <div className="planet-container">
              <span className="planet-title">Venus</span>
              <div className="planer-cnt">
                <img src={Venus} alt="mercury" />
              </div>
            </div>
            <div className="planet-info">
              <span>
                Mass: 0000 <InlineMath math="kg" />{" "}
              </span>
              <span>
                Distance: 0000 <InlineMath math="km" />
              </span>
              <span>
                Gravity: 0000 <InlineMath math="\text{m/s}^2" />
              </span>
            </div>
          </Grid>
          <Grid item sx={3}>
            <div className="planet-container">
              <span className="planet-title">Earth</span>
              <div className="planer-cnt">
                <img src={Earth} alt="mercury" width={"160px"} />
              </div>
            </div>
            <div className="planet-info">
              <span>
                Mass: 0000 <InlineMath math="kg" />{" "}
              </span>
              <span>
                Distance: 0000 <InlineMath math="km" />
              </span>
              <span>
                Gravity: 0000 <InlineMath math="\text{m/s}^2" />
              </span>
            </div>
          </Grid>
          <Grid item sx={3}>
            <div className="planet-container">
              <span className="planet-title">Moon</span>
              <div className="planer-cnt">
                <img src={Moon} alt="mercury" />
              </div>
            </div>
            <div className="planet-info">
              <span>
                Mass: 0000 <InlineMath math="kg" />{" "}
              </span>
              <span>
                Distance: 0000 <InlineMath math="km" />
              </span>
              <span>
                Gravity: 0000 <InlineMath math="\text{m/s}^2" />
              </span>
            </div>
          </Grid>
          <Grid item sx={3}>
            <div className="planet-container">
              <span className="planet-title">Mars</span>
              <div className="planer-cnt">
                <img src={Venus} alt="mercury" />
              </div>
            </div>
            <div className="planet-info">
              <span>
                Mass: 0000 <InlineMath math="kg" />{" "}
              </span>
              <span>
                Distance: 0000 <InlineMath math="km" />
              </span>
              <span>
                Gravity: 0000 <InlineMath math="\text{m/s}^2" />
              </span>
            </div>
          </Grid>
        </Grid>

        <div style={{ display: "flex", gap: "20px" }}>
          <div className="fill-size-planet-container">
            <span className="title-chart">Mercury</span>
            <div className="planer-cnt">
              <img src={MercuryLarge} alt="mercury-large" width={"400px"} />
            </div>
          </div>
          <div className="fill-size-planet-container">
            <span className="title-chart">Venus</span>
            <div className="planer-cnt">
              <img src={VenusLarge} alt="mercury-large" width={"400px"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visualization;
