





import React from "react"

function likeTest() {
    return (
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="1565103_like_like button_liked_likes_thumbs up_icon 1" clip-path="url(#clip0_3539_8330)">
        <g id="Group">
        <g id="Vector">
        <mask id="path-1-inside-1_3539_8330" fill="white">
        <path d="M2.34315 10H0.788154C0.357217 10 0.0065918 9.64937 0.0065918 9.21812V4.53187C0.0065918 4.10094 0.357217 3.75 0.788154 3.75H2.81253C2.98503 3.75 3.12503 3.89 3.12503 4.0625V9.21812C3.12503 9.64937 2.77409 10 2.34315 10ZM0.788154 4.375C0.701904 4.375 0.631592 4.44531 0.631592 4.53187V9.21812C0.631592 9.30437 0.701904 9.375 0.788154 9.375H2.34315C2.42972 9.375 2.50003 9.30437 2.50003 9.21812V4.375H0.788154Z"/>
        </mask>
        <path d="M2.34315 10H0.788154C0.357217 10 0.0065918 9.64937 0.0065918 9.21812V4.53187C0.0065918 4.10094 0.357217 3.75 0.788154 3.75H2.81253C2.98503 3.75 3.12503 3.89 3.12503 4.0625V9.21812C3.12503 9.64937 2.77409 10 2.34315 10ZM0.788154 4.375C0.701904 4.375 0.631592 4.44531 0.631592 4.53187V9.21812C0.631592 9.30437 0.701904 9.375 0.788154 9.375H2.34315C2.42972 9.375 2.50003 9.30437 2.50003 9.21812V4.375H0.788154Z" fill="white"/>
        <path d="M2.50003 4.375H3.50003V3.375H2.50003V4.375ZM2.34315 9H0.788154V11H2.34315V9ZM0.788154 9C0.909762 9 1.00659 9.09735 1.00659 9.21812H-0.993408C-0.993408 10.2014 -0.195329 11 0.788154 11V9ZM1.00659 9.21812V4.53187H-0.993408V9.21812H1.00659ZM1.00659 4.53187C1.00659 4.6527 0.910022 4.75 0.788154 4.75V2.75C-0.195588 2.75 -0.993408 3.54917 -0.993408 4.53187H1.00659ZM0.788154 4.75H2.81253V2.75H0.788154V4.75ZM2.81253 4.75C2.43274 4.75 2.12503 4.44228 2.12503 4.0625H4.12503C4.12503 3.33772 3.53731 2.75 2.81253 2.75V4.75ZM2.12503 4.0625V9.21812H4.12503V4.0625H2.12503ZM2.12503 9.21812C2.12503 9.09683 2.22207 9 2.34315 9V11C3.32612 11 4.12503 10.2019 4.12503 9.21812H2.12503ZM0.788154 3.375C0.148323 3.375 -0.368408 3.89432 -0.368408 4.53187H1.63159C1.63159 4.9963 1.25549 5.375 0.788154 5.375V3.375ZM-0.368408 4.53187V9.21812H1.63159V4.53187H-0.368408ZM-0.368408 9.21812C-0.368408 9.85406 0.147026 10.375 0.788154 10.375V8.375C1.25678 8.375 1.63159 8.75469 1.63159 9.21812H-0.368408ZM0.788154 10.375H2.34315V8.375H0.788154V10.375ZM2.34315 10.375C2.9833 10.375 3.50003 9.85536 3.50003 9.21812H1.50003C1.50003 8.75339 1.87613 8.375 2.34315 8.375V10.375ZM3.50003 9.21812V4.375H1.50003V9.21812H3.50003ZM2.50003 3.375H0.788154V5.375H2.50003V3.375Z" fill="white" mask="url(#path-1-inside-1_3539_8330)"/>
        </g>
        <path id="Vector_2" d="M1.375 8.4375C1.375 8.33395 1.45895 8.25 1.5625 8.25C1.66605 8.25 1.75 8.33395 1.75 8.4375C1.75 8.54105 1.66605 8.625 1.5625 8.625C1.45895 8.625 1.375 8.54105 1.375 8.4375Z" fill="white" stroke="white"/>
        <path id="Vector_3" d="M5.25904 1.05436L5.26027 1.05529L5.25904 1.05436Z" fill="white" stroke="white"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_3539_8330">
        <rect width="10" height="10" fill="white"/>
        </clipPath>
        </defs>
      </svg>
        
    );
}

export default likeTest;