


import React from "react"

function SearchIcon() {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="10.5416" cy="10.0833" rx="6.70833" ry="6.41667" stroke="#A8B0C9" strokeWidth="2"/>
        <path d="M10.5417 7.33337C10.1642 7.33337 9.79034 7.4045 9.44153 7.54271C9.09272 7.68091 8.77578 7.88347 8.50882 8.13883C8.24185 8.39419 8.03008 8.69735 7.88559 9.03099C7.74111 9.36464 7.66675 9.72224 7.66675 10.0834" stroke="#A8B0C9" strokeWidth="2" strokeLinecap="round"/>
        <path d="M19.1667 18.3334L16.2917 15.5834" stroke="#A8B0C9" strokeWidth="2" strokeLinecap="round"/>
        </svg>
                
    );
}

export default SearchIcon;