import React from "react";
import "./DuplicateCard.css";
import { Avatar, Typography, styled } from "@mui/material";

import ThreeDImage from "../../assets/images/3d-viewer-rectangle@2x.png";
import SvgOneIcon from "../../assets/svg/svgOne";
import SvgTwoIcon from "../../assets/svg/svgTwo";
import SvgThreeIcon from "../../assets/svg/svgThree";
import SvgFourIcon from "../../assets/svg/svgFour";
import SvgFiveIcon from "../../assets/svg/svgFive";
import CertificationIconOne from "../../assets/svg/certificate_diploma_seal_icon 2";
import CertificationIconTwo from "../../assets/svg/certificate_diploma_seal_icon 3";
import CertificationIconThree from "../../assets/svg/certificate_diploma_seal";
import ViewDialog from "../ViewDialog";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name = "-", width = 60, height = 60, fontSize = 24) {
  const childrenValue = `${name.split(" ")[0][0]}${
    name?.split(" ")?.[1]?.[0] || ""
  }`;
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: width,
      height: height,
      marginRight: "5px",
      fontSize: `${fontSize}px`,
    },
    children: childrenValue,
  };
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b1c30",
    color: "#b5b5db",
    boxShadow: theme.shadows[1],
    border: "1px solid #505083",
    fontSize: 11,
  },
}));

const DuplicateCard = () => {
  const [viewModal, setViewModal] = React.useState(false);

  const handleOpenViewDialog = () => {
    setViewModal(true);
  };
  const handleCloseViewDialog = () => {
    setViewModal(false);
  };

  return (
    <>
      <div className="propstandard-duplicate" onClick={handleOpenViewDialog}>
        <img alt="No image" src={ThreeDImage} />
      </div>
      <div className="infoField">
        <Typography variant="h4" className="rec-title">
          Pigeon
        </Typography>
        <div className="avatar-cnt" style={{ marginTop: 0 }}>
          <Avatar {...stringAvatar(`Creator Name`, 15, 15, 6)} />
          <p className="user_name-rec">{`Creator Name`}</p>
        </div>
        <p className="rec-description">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </p>
        <div className="card-icon-container">
          <div>
            {[1,2,3,4,5].map((iconValue) => {
              return viewIcons[iconValue];
            })}
          </div>
          <span className="cost-title">
            $250
          </span>
        </div>
        <div className="license-icon-container">
          <span className="license-title">License Type</span>
          <div>
            <CertificationIconOne />
            <CertificationIconTwo />
            <CertificationIconThree />
          </div>
        </div>
      </div>
      {/* {viewModal && (
        <ViewDialog
          open={viewModal}
          onClose={handleCloseViewDialog}
          cardInfo={cardInfo}
        />
      )} */}
    </>
  );
};

const viewIcons = {
  1: (
    <LightTooltip
      title="Model based on some image & clearly depicts a structure or process"
      placement="top"
    >
      <span>
        <SvgOneIcon />
      </span>
    </LightTooltip>
  ),
  2: (
    <LightTooltip
      title="Model based on composited Technical Imaging"
      placement="top"
    >
      <span>
        <SvgTwoIcon />
      </span>
    </LightTooltip>
  ),
  3: (
    <LightTooltip title="Citation used to further edit & model" placement="top">
      <span>
        <SvgThreeIcon />
      </span>
    </LightTooltip>
  ),
  4: (
    <LightTooltip
      title="Academic or Scientific Research Partnership"
      placement="top"
    >
      <span>
        <SvgFourIcon />
      </span>
    </LightTooltip>
  ),
  5: (
    <LightTooltip title="Published in Peer-Reviewed Journal" placement="top">
      <span>
        <SvgFiveIcon />
      </span>
    </LightTooltip>
  ),
};

export default DuplicateCard;
