

import React from "react"

function SvgFiveIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="9.99988" r="9.25" stroke="#B4B4FA" strokeWidth="0.5" />
            <g clipPath="url(#clip0_2022_8752)">
                <path d="M15.0793 16.35H5.14196C5.06346 16.35 5 16.2865 5 16.208V3.42535C5 3.34681 5.06346 3.28333 5.14196 3.28333H15.0793C15.1578 3.28333 15.2212 3.34681 15.2212 3.42535V16.208C15.2212 16.2865 15.1577 16.35 15.0793 16.35ZM5.28392 16.0659H14.9373V3.56738H5.28392V16.0659Z" fill="#B4B4FA" />
                <path d="M6.03724 9.79381H4.6469C4.58419 9.79381 4.53345 9.74391 4.53345 9.68224C4.53345 9.62058 4.58419 9.57068 4.6469 9.57068H6.03724C6.09994 9.57068 6.15069 9.62058 6.15069 9.68224C6.15069 9.74391 6.10008 9.79381 6.03724 9.79381Z" fill="#B4B4FA" />
                <path d="M6.03724 11.1611H4.6469C4.58419 11.1611 4.53345 11.1112 4.53345 11.0496C4.53345 10.9879 4.58419 10.938 4.6469 10.938H6.03724C6.09994 10.938 6.15069 10.9879 6.15069 11.0496C6.15069 11.1112 6.10008 11.1611 6.03724 11.1611Z" fill="#B4B4FA" />
                <path d="M6.03724 12.5282H4.6469C4.58419 12.5282 4.53345 12.4783 4.53345 12.4166C4.53345 12.355 4.58419 12.3051 4.6469 12.3051H6.03724C6.09994 12.3051 6.15069 12.355 6.15069 12.4166C6.15069 12.4783 6.10008 12.5282 6.03724 12.5282Z" fill="#B4B4FA" />
                <path d="M6.03724 13.8955H4.6469C4.58419 13.8955 4.53345 13.8456 4.53345 13.7839C4.53345 13.7223 4.58419 13.6724 4.6469 13.6724H6.03724C6.09994 13.6724 6.15069 13.7223 6.15069 13.7839C6.15069 13.8456 6.10008 13.8955 6.03724 13.8955Z" fill="#B4B4FA" />
                <path d="M6.03724 15.2626H4.6469C4.58419 15.2626 4.53345 15.2127 4.53345 15.151C4.53345 15.0893 4.58419 15.0394 4.6469 15.0394H6.03724C6.09994 15.0394 6.15069 15.0893 6.15069 15.151C6.15069 15.2127 6.10008 15.2626 6.03724 15.2626Z" fill="#B4B4FA" />
                <path d="M6.03724 7.05943H4.6469C4.58419 7.05943 4.53345 7.00953 4.53345 6.94787C4.53345 6.88621 4.58419 6.8363 4.6469 6.8363H6.03724C6.09994 6.8363 6.15069 6.88621 6.15069 6.94787C6.15069 7.00953 6.10008 7.05943 6.03724 7.05943Z" fill="#B4B4FA" />
                <path d="M6.03724 8.4265H4.6469C4.58419 8.4265 4.53345 8.3766 4.53345 8.31493C4.53345 8.25327 4.58419 8.20337 4.6469 8.20337H6.03724C6.09994 8.20337 6.15069 8.25327 6.15069 8.31493C6.15069 8.3766 6.10008 8.4265 6.03724 8.4265Z" fill="#B4B4FA" />
                <path d="M6.03724 4.32506H4.6469C4.58419 4.32506 4.53345 4.27516 4.53345 4.21349C4.53345 4.15183 4.58419 4.10193 4.6469 4.10193H6.03724C6.09994 4.10193 6.15069 4.15183 6.15069 4.21349C6.15069 4.27516 6.10008 4.32506 6.03724 4.32506Z" fill="#B4B4FA" />
                <path d="M6.03724 5.69225H4.6469C4.58419 5.69225 4.53345 5.64234 4.53345 5.58068C4.53345 5.51902 4.58419 5.46912 4.6469 5.46912H6.03724C6.09994 5.46912 6.15069 5.51902 6.15069 5.58068C6.15069 5.64234 6.10008 5.69225 6.03724 5.69225Z" fill="#B4B4FA" />
                <path d="M12.6374 8.38348H7.88356C7.44045 8.38348 7.08008 8.02909 7.08008 7.5935V6.30257C7.08008 5.86683 7.44059 5.51245 7.88356 5.51245H12.6374C13.0805 5.51245 13.4407 5.86697 13.4407 6.30257V7.5935C13.4407 8.02909 13.0805 8.38348 12.6374 8.38348ZM7.88356 5.64904C7.51706 5.64904 7.21911 5.94217 7.21911 6.30243V7.59336C7.21911 7.95362 7.5172 8.24662 7.88356 8.24662H12.6374C13.0038 8.24662 13.3017 7.95349 13.3017 7.59336V6.30243C13.3017 5.94203 13.0036 5.64904 12.6374 5.64904H7.88356Z" fill="#B4B4FA" />
                <path d="M12.2415 6.48246H8.07051C8.03422 6.48246 8.00488 6.45361 8.00488 6.41793C8.00488 6.38224 8.03422 6.35339 8.07051 6.35339H12.2415C12.2778 6.35339 12.3072 6.38224 12.3072 6.41793C12.3072 6.45361 12.2777 6.48246 12.2415 6.48246Z" fill="#B4B4FA" />
                <path d="M12.2415 7.02982H8.07051C8.03422 7.02982 8.00488 7.00097 8.00488 6.96529C8.00488 6.92961 8.03422 6.90076 8.07051 6.90076H12.2415C12.2778 6.90076 12.3072 6.92961 12.3072 6.96529C12.3072 7.00097 12.2777 7.02982 12.2415 7.02982Z" fill="#B4B4FA" />
                <path d="M12.2415 7.57645H8.07051C8.03422 7.57645 8.00488 7.5476 8.00488 7.51192C8.00488 7.47624 8.03422 7.44739 8.07051 7.44739H12.2415C12.2778 7.44739 12.3072 7.47624 12.3072 7.51192C12.3072 7.5476 12.2777 7.57645 12.2415 7.57645Z" fill="#B4B4FA" />
            </g>
            <defs>
                <clipPath id="clip0_2022_8752">
                    <rect width="14" height="14" fill="white" transform="translate(5 2.34998)" />
                </clipPath>
            </defs>
        </svg>


    );
}

export default SvgFiveIcon;