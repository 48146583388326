



import React from "react"

function CitationIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.12508 3.5C6.12508 2.5375 5.33758 1.75 4.37508 1.75H2.62508C1.66258 1.75 0.875082 2.5375 0.875082 3.5V6.125C0.875082 7.0875 1.66258 7.875 2.62508 7.875H3.50008C3.97696 7.875 4.36021 8.25738 4.37158 8.73163V8.78237C4.37158 10.2104 3.19558 11.3724 1.75008 11.3724C1.26446 11.3724 0.871582 11.7653 0.871582 12.2509C0.871582 12.7365 1.26446 13.1285 1.75008 13.1285C4.16421 13.1285 6.12858 11.1781 6.12858 8.7815L6.12508 3.5Z" fill="white" />
            <path d="M13.1251 3.5C13.1251 2.5375 12.3376 1.75 11.3751 1.75H9.62508C8.66258 1.75 7.87508 2.5375 7.87508 3.5V6.125C7.87508 7.0875 8.66258 7.875 9.62508 7.875H10.5001C10.9778 7.875 11.362 8.25825 11.3716 8.7325V8.7815C11.3716 10.2095 10.1947 11.3715 8.75008 11.3715C8.26446 11.3715 7.87158 11.7644 7.87158 12.25C7.87158 12.7356 8.26533 13.1285 8.75008 13.1285C11.1651 13.1285 13.1286 11.1781 13.1286 8.7815L13.1251 3.5Z" fill="white" />
        </svg>
    );
}

export default CitationIcon;