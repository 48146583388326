import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#1976d2', // primary main color
      white: "#ffffff",
      black: "#000000",
      bgColor: "#1B1C30",
      yellow: "#FBBD00"
    },
    secondary: {
      main: '#ff4081', // secondary main color
      dustyPurple40: "#7474B8",
      dustyPurple20: "#B4B4FA",
      dustyPurple70: "#282845",
      lightgrey: "#F5F5F5"
    },
    // ... add other theme customizations here
  },
});

export default theme;