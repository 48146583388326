





import React from "react"

function CornerFrameIcon() {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.5" cy="14.5" r="13.75" stroke="#B4B4FA" stroke-width="0.5"/>
        <g clip-path="url(#clip0_3506_62)">
        <path d="M4.49366 10.8094C4.7653 10.8094 4.98756 10.5872 4.98756 10.3155V7.96949H7.3336C7.60524 7.96949 7.8275 7.74724 7.8275 7.47559C7.8275 7.20395 7.60524 6.98169 7.3336 6.98169H4.49366C4.22201 6.98169 3.99976 7.20395 3.99976 7.47559V10.3155C3.99976 10.5872 4.22201 10.8094 4.49366 10.8094Z" fill="#B4B4FA"/>
        <path d="M6.59274 18.8353H4.98756V16.4893C4.98756 16.2176 4.7653 15.9954 4.49366 15.9954C4.22201 15.9954 3.99976 16.2176 3.99976 16.4893V19.3292C3.99976 19.6008 4.22201 19.8231 4.49366 19.8231H6.59274C6.86439 19.8231 7.08665 19.6008 7.08665 19.3292C7.08665 19.0576 6.86439 18.8353 6.59274 18.8353Z" fill="#B4B4FA"/>
        <path d="M19.3106 6.98169H16.4707C16.1991 6.98169 15.9768 7.20395 15.9768 7.47559C15.9768 7.74724 16.1991 7.96949 16.4707 7.96949H18.8167V9.08078C18.8167 9.35242 19.039 9.32773 19.3106 9.32773C19.5823 9.32773 19.8046 9.35242 19.8046 9.08078V7.47559C19.8046 7.20395 19.5823 6.98169 19.3106 6.98169Z" fill="#B4B4FA"/>
        </g>
        <g clip-path="url(#clip1_3506_62)">
        <path d="M6.71631 12.2909C6.98796 12.2909 7.21022 12.0686 7.21022 11.797V9.45094H9.55625C9.8279 9.45094 10.0502 9.22868 10.0502 8.95704C10.0502 8.68539 9.8279 8.46313 9.55625 8.46313H6.71631C6.44467 8.46313 6.22241 8.68539 6.22241 8.95704V11.797C6.22241 12.0686 6.44467 12.2909 6.71631 12.2909Z" fill="#B4B4FA"/>
        <path d="M8.69192 20.3167H7.21022V17.9707C7.21022 17.6991 6.98796 17.4768 6.71631 17.4768C6.44467 17.4768 6.22241 17.6991 6.22241 17.9707V20.8106C6.22241 21.0823 6.44467 21.3046 6.71631 21.3046H8.69192C8.96357 21.3046 9.18583 21.0823 9.18583 20.8106C9.18583 20.539 8.96357 20.3167 8.69192 20.3167Z" fill="#B4B4FA"/>
        <path d="M21.5333 8.46313H18.6934C18.4217 8.46313 18.1995 8.68539 18.1995 8.95704C18.1995 9.22868 18.4217 9.45094 18.6934 9.45094H21.0394V10.4387C21.0394 10.7104 21.2617 10.9326 21.5333 10.9326C21.805 10.9326 22.0272 10.7104 22.0272 10.4387V8.95704C22.0272 8.68539 21.805 8.46313 21.5333 8.46313Z" fill="#B4B4FA"/>
        </g>
        <g clip-path="url(#clip2_3506_62)">
        <path d="M8.93897 13.7728C9.21062 13.7728 9.43287 13.5506 9.43287 13.2789V10.9329H11.7789C12.0506 10.9329 12.2728 10.7106 12.2728 10.439C12.2728 10.1673 12.0506 9.94507 11.7789 9.94507H8.93897C8.66732 9.94507 8.44507 10.1673 8.44507 10.439V13.2789C8.44507 13.5506 8.66732 13.7728 8.93897 13.7728Z" fill="#B4B4FA"/>
        <path d="M11.7789 21.7987H9.43287V19.4526C9.43287 19.181 9.21062 18.9587 8.93897 18.9587C8.66732 18.9587 8.44507 19.181 8.44507 19.4526V22.2926C8.44507 22.5642 8.66732 22.7865 8.93897 22.7865H11.7789C12.0506 22.7865 12.2728 22.5642 12.2728 22.2926C12.2728 22.0209 12.0506 21.7987 11.7789 21.7987Z" fill="#B4B4FA"/>
        <path d="M23.756 18.9587C23.4843 18.9587 23.2621 19.181 23.2621 19.4526V21.7987H20.916C20.6444 21.7987 20.4221 22.0209 20.4221 22.2926C20.4221 22.5642 20.6444 22.7865 20.916 22.7865H23.756C24.0276 22.7865 24.2499 22.5642 24.2499 22.2926V19.4526C24.2499 19.181 24.0276 18.9587 23.756 18.9587Z" fill="#B4B4FA"/>
        <path d="M23.756 9.94507H20.916C20.6444 9.94507 20.4221 10.1673 20.4221 10.439C20.4221 10.7106 20.6444 10.9329 20.916 10.9329H23.2621V13.2789C23.2621 13.5506 23.4843 13.7728 23.756 13.7728C24.0276 13.7728 24.2499 13.5506 24.2499 13.2789V10.439C24.2499 10.1673 24.0276 9.94507 23.756 9.94507Z" fill="#B4B4FA"/>
        <path d="M18.7182 16.8597H20.6197C20.8914 16.8597 21.1136 16.6374 21.1136 16.3658C21.1136 16.0941 20.8914 15.8719 20.6197 15.8719H18.7182C18.5206 14.9335 17.7798 14.1926 16.8414 13.995V12.0935C16.8414 11.8219 16.6191 11.5996 16.3475 11.5996C16.0758 11.5996 15.8536 11.8219 15.8536 12.0935V13.995C14.9151 14.1926 14.1743 14.9335 13.9767 15.8719H12.0752C11.8036 15.8719 11.5813 16.0941 11.5813 16.3658C11.5813 16.6374 11.8036 16.8597 12.0752 16.8597H13.9767C14.1743 17.7981 14.9151 18.5389 15.8536 18.7365V20.638C15.8536 20.9097 16.0758 21.1319 16.3475 21.1319C16.6191 21.1319 16.8414 20.9097 16.8414 20.638V18.7365C17.7798 18.5389 18.5206 17.7981 18.7182 16.8597ZM16.3475 17.7981C15.5572 17.7981 14.9151 17.156 14.9151 16.3658C14.9151 15.5755 15.5572 14.9335 16.3475 14.9335C17.1377 14.9335 17.7798 15.5755 17.7798 16.3658C17.7798 17.156 17.1377 17.7981 16.3475 17.7981Z" fill="#B4B4FA"/>
        </g>
        <defs>
        <clipPath id="clip0_3506_62">
        <rect width="15.8049" height="15.8049" fill="white" transform="translate(3.99976 5.5)"/>
        </clipPath>
        <clipPath id="clip1_3506_62">
        <rect width="15.8049" height="15.8049" fill="white" transform="translate(6.22241 6.98145)"/>
        </clipPath>
        <clipPath id="clip2_3506_62">
        <rect width="15.8049" height="15.8049" fill="white" transform="translate(8.44507 8.46338)"/>
        </clipPath>
        </defs>
        </svg>
    );
}

export default CornerFrameIcon;