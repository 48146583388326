




import React from "react"

function FlagIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 19.5L12 16.5L6.5 19.5V4.5H17.5V19.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default FlagIcon;