





import React from "react"

function ChatIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.03 14.6201C20.49 13.7051 20.7225 12.7301 20.7225 11.7276C20.7225 10.7251 20.49 9.7501 20.03 8.8351C19.5875 7.9551 18.9575 7.1676 18.155 6.4926C16.5175 5.1101 14.3425 4.3501 12.0325 4.3501C9.72247 4.3501 7.54997 5.1101 5.91247 6.4901C5.10997 7.1651 4.47747 7.9551 4.03747 8.8326C3.57747 9.7476 3.34497 10.7226 3.34497 11.7251C3.34497 13.8401 4.40247 15.8326 6.25497 17.2326L4.78747 20.8976L10.01 18.8976C10.67 19.0326 11.3525 19.1001 12.0375 19.1001C14.3475 19.1001 16.52 18.3401 18.16 16.9626C18.9575 16.2876 19.5875 15.4976 20.03 14.6201ZM10.0525 18.1426L9.94247 18.1201L6.11497 19.5851L7.16247 16.9651L6.91997 16.7951C5.12497 15.5301 4.09247 13.6826 4.09247 11.7251C4.09247 8.0726 7.65497 5.1001 12.0325 5.1001C16.41 5.1001 19.9725 8.0726 19.9725 11.7251C19.9725 15.3776 16.4125 18.3501 12.0325 18.3501C11.3625 18.3501 10.6975 18.2801 10.0525 18.1426Z" fill="white" />
        </svg>
    );
}

export default ChatIcon;