import React from "react"

function CertificationIconSeven() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="15" version="1.0">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path
            fill="#000"
            fillOpacity="1"
            stroke="none"
            strokeWidth=".92243534"
            strokeMiterlimit="4"
            strokeDasharray="none"
            strokeOpacity="1"
            d="M171.998 239.12h20.614v12.484h-20.614z"
          />
        </clipPath>
      </defs>
      <path
        fill="#fff"
        fillOpacity="1"
        stroke="#000"
        strokeWidth="1.04161763"
        strokeMiterlimit="4"
        strokeDasharray="none"
        strokeOpacity="1"
        d="M170.5 237.862h80v15h-80z"
        transform="matrix(.9875 0 0 .93335 -167.875 -221.477)"
      />
      <path
        fill="#000"
        fillOpacity="1"
        stroke="none"
        strokeWidth=".92243534"
        strokeMiterlimit="4"
        strokeDasharray="none"
        strokeOpacity="1"
        d="M172 239.362h77v12h-77z"
        transform="matrix(.9875 0 0 .93335 -167.875 -221.477)"
      />
      <path
        fill="#abb1aa"
        fillOpacity="1"
        stroke="none"
        strokeWidth="1"
        strokeMiterlimit="4"
        strokeDasharray="none"
        strokeOpacity=".46913578"
        d="M172 239.375v12h20.335c1.308-1.75 2.192-3.784 2.192-6 0-2.2-.868-4.257-2.161-6H172z"
        transform="matrix(.9875 0 0 .93335 -167.875 -221.477)"
      />
      <g transform="matrix(.94923 0 0 .89718 -161.126 -212.604)" clipPath="url(#a)">
        <path
          d="M190.064 245.362a7.047 7.047 0 0 1-14.094.009v-.009a7.047 7.047 0 1 1 14.094-.008v.008z"
          style={{ opacity: 1, fill: "#fff" }}
        />
        <path
          d="M188.746 239.622c1.562 1.563 2.344 3.477 2.344 5.74 0 2.264-.768 4.157-2.304 5.68-1.63 1.603-3.556 2.405-5.779 2.405-2.196 0-4.089-.795-5.678-2.385-1.59-1.59-2.385-3.49-2.385-5.7s.795-4.123 2.385-5.74c1.549-1.563 3.442-2.344 5.678-2.344 2.264 0 4.176.781 5.739 2.344zm-10.365 1.052c-1.321 1.334-1.982 2.897-1.982 4.69 0 1.793.654 3.343 1.962 4.65 1.307 1.308 2.864 1.961 4.67 1.961s3.376-.66 4.71-1.98c1.268-1.228 1.901-2.77 1.901-4.63 0-1.847-.644-3.414-1.931-4.701-1.287-1.288-2.847-1.931-4.68-1.931-1.833 0-3.383.647-4.65 1.94zm3.477 3.902c-.202-.44-.504-.66-.907-.66-.713 0-1.07.479-1.07 1.438 0 .96.357 1.439 1.07 1.439.47 0 .806-.234 1.008-.702l.987.526c-.47.836-1.177 1.255-2.118 1.255-.727 0-1.308-.223-1.745-.668-.438-.445-.656-1.06-.656-1.842 0-.769.225-1.38.676-1.831.45-.453 1.012-.679 1.684-.679.996 0 1.709.393 2.14 1.176l-1.07.548zm4.646 0c-.202-.44-.498-.66-.889-.66-.727 0-1.09.479-1.09 1.438 0 .96.363 1.439 1.09 1.439.471 0 .801-.234.99-.702l1.01.526c-.47.836-1.176 1.255-2.116 1.255-.725 0-1.306-.223-1.742-.668-.436-.445-.654-1.06-.654-1.842 0-.769.221-1.38.664-1.831.443-.453 1.007-.679 1.692-.679.994 0 1.706.393 2.135 1.176l-1.09.548z"
          style={{ opacity: 1 }}
        />
      </g>
      <path
        d="M357.42 448.685c.245 0 .431-.054.558-.16.127-.108.19-.266.19-.475 0-.206-.063-.363-.19-.47-.127-.11-.313-.165-.558-.165h-.862v1.27h.862m.052 2.624c.313 0 .548-.065.704-.197.16-.13.24-.33.24-.594 0-.26-.08-.455-.236-.583-.156-.131-.392-.197-.708-.197h-.914v1.571h.914m1.449-2.158c.334.097.593.275.777.535.183.26.275.578.275.956 0 .579-.197 1.01-.59 1.294-.395.284-.994.426-1.797.426H355v-6h2.339c.839 0 1.446.126 1.82.378.378.252.567.655.567 1.21 0 .292-.069.54-.206.747a1.218 1.218 0 0 1-.6.454m.917-2.789h1.704l1.376 2.138 1.375-2.138H366l-2.302 3.472v2.528h-1.558v-2.528l-2.303-3.472m5.321 3.046h2.541v1.17h-2.54v-1.17m3.758-3.046h1.74l2.197 4.115v-4.115h1.477v6h-1.74l-2.197-4.115v4.115h-1.477v-6m11.735 5.67a3.996 3.996 0 0 1-1.866.447c-1.006 0-1.803-.279-2.391-.836-.588-.56-.882-1.318-.882-2.275 0-.959.294-1.717.882-2.274.588-.56 1.385-.84 2.391-.84a3.997 3.997 0 0 1 1.865.446v1.242a3.408 3.408 0 0 0-.853-.43 2.852 2.852 0 0 0-.886-.137c-.556 0-.993.177-1.311.53-.319.354-.478.842-.478 1.463 0 .62.16 1.105.478 1.459.318.354.755.53 1.31.53.311 0 .606-.045.887-.136a3.44 3.44 0 0 0 .854-.43v1.242m1.177-2.625h2.541v1.17h-2.54v-1.17m3.758-3.046h1.74l2.197 4.115v-4.115h1.477v6h-1.74l-2.197-4.115v4.115h-1.477v-6m8.502 1.17v3.66h.558c.637 0 1.122-.156 1.457-.47.337-.313.505-.768.505-1.366 0-.595-.167-1.047-.501-1.358-.335-.31-.822-.466-1.461-.466h-.558m-1.558-1.17h1.643c.917 0 1.6.066 2.047.197a2.7 2.7 0 0 1 1.157.66c.283.27.494.582.631.936.138.353.207.754.207 1.201 0 .453-.069.858-.207 1.214a2.573 2.573 0 0 1-.63.936 2.764 2.764 0 0 1-1.166.663c-.453.129-1.133.193-2.04.193h-1.642v-6"
        style={{
          fontSize: "8.25858784px",
          fontStyle: "normal",
          fontWeight: "700",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: "1px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
          fontFamily: "Bitstream Vera Sans",
        }}
        transform="matrix(.9875 0 0 .93335 -323.9 -411.88)"
      />
    </svg>
  );
}

export default CertificationIconSeven;
