
import React from "react"

function Xicon() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill="white" />
            <path d="M18.6828 6.6875H21.1649L15.7438 12.882L22.1211 21.3125H17.1289L13.216 16.2008L8.74415 21.3125H6.25861L12.0559 14.6855L5.9422 6.6875H11.0609L14.5942 11.3598L18.6828 6.6875ZM17.8109 19.8289H19.1856L10.3121 8.09375H8.83556L17.8109 19.8289Z" fill="#282845" />
        </svg>
    );
}

export default Xicon;

