
import React from "react"

function TwitterIcon() {
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.6323 2.71875H26.6311L17.8971 12.6988L28.1717 26.2812H20.1288L13.8247 18.0457L6.61997 26.2812H2.61548L11.9555 15.6045L2.10571 2.71875H10.3526L16.045 10.2463L22.6323 2.71875ZM21.2276 23.891H23.4422L9.14614 4.98438H6.76724L21.2276 23.891Z" fill="#1B1C30" />
        </svg>
    );
}

export default TwitterIcon;