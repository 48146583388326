





import React from "react"

function FrameIcon() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="12.75" stroke="#B4B4FA" stroke-width="0.5"/>
        <path d="M21.2784 23.0276H7.25598C7.14521 23.0276 7.05566 22.9365 7.05566 22.8238V4.48139C7.05566 4.36869 7.14521 4.27759 7.25598 4.27759H21.2784C21.3892 4.27759 21.4787 4.36869 21.4787 4.48139V22.8238C21.4787 22.9365 21.389 23.0276 21.2784 23.0276ZM7.45631 22.62H21.0781V4.6852H7.45631V22.62Z" fill="#B4B4FA"/>
        <path d="M19.86 5.90517H8.67356C8.57624 5.90517 8.49756 5.86378 8.49756 5.81257C8.49756 5.76137 8.57624 5.71997 8.67356 5.71997H19.86C19.9573 5.71997 20.036 5.76137 20.036 5.81257C20.036 5.86378 19.957 5.90517 19.86 5.90517Z" fill="#B4B4FA"/>
        <path d="M19.86 8.78994H8.67356C8.57624 8.78994 8.49756 8.74854 8.49756 8.69734C8.49756 8.64613 8.57624 8.60474 8.67356 8.60474H19.86C19.9573 8.60474 20.036 8.64613 20.036 8.69734C20.036 8.74854 19.957 8.78994 19.86 8.78994Z" fill="#B4B4FA"/>
        <path d="M19.86 11.6747H8.67356C8.57624 11.6747 8.49756 11.6333 8.49756 11.5821C8.49756 11.5309 8.57624 11.4895 8.67356 11.4895H19.86C19.9573 11.4895 20.036 11.5309 20.036 11.5821C20.036 11.6333 19.957 11.6747 19.86 11.6747Z" fill="#B4B4FA"/>
        <path d="M19.86 14.559H8.67356C8.57624 14.559 8.49756 14.5176 8.49756 14.4664C8.49756 14.4152 8.57624 14.3738 8.67356 14.3738H19.86C19.9573 14.3738 20.036 14.4152 20.036 14.4664C20.036 14.5176 19.957 14.559 19.86 14.559Z" fill="#B4B4FA"/>
        <path d="M19.86 17.444H8.67356C8.57624 17.444 8.49756 17.4026 8.49756 17.3514C8.49756 17.3002 8.57624 17.2588 8.67356 17.2588H19.86C19.9573 17.2588 20.036 17.3002 20.036 17.3514C20.036 17.4026 19.957 17.444 19.86 17.444Z" fill="#B4B4FA"/>
        <path d="M19.8821 6.62661H10.0939C10.0088 6.62661 9.93994 6.58521 9.93994 6.53401C9.93994 6.4828 10.0088 6.44141 10.0939 6.44141H19.8821C19.9673 6.44141 20.0361 6.4828 20.0361 6.53401C20.0361 6.58521 19.9669 6.62661 19.8821 6.62661Z" fill="#B4B4FA"/>
        <path d="M19.8821 9.51113H10.0939C10.0088 9.51113 9.93994 9.46973 9.93994 9.41853C9.93994 9.36732 10.0088 9.32593 10.0939 9.32593H19.8821C19.9673 9.32593 20.0361 9.36732 20.0361 9.41853C20.0361 9.46973 19.9669 9.51113 19.8821 9.51113Z" fill="#B4B4FA"/>
        <path d="M19.8821 12.3957H10.0939C10.0088 12.3957 9.93994 12.3543 9.93994 12.3031C9.93994 12.2518 10.0088 12.2104 10.0939 12.2104H19.8821C19.9673 12.2104 20.0361 12.2518 20.0361 12.3031C20.0361 12.3543 19.9669 12.3957 19.8821 12.3957Z" fill="#B4B4FA"/>
        <path d="M19.8821 15.2804H10.0939C10.0088 15.2804 9.93994 15.239 9.93994 15.1878C9.93994 15.1366 10.0088 15.0952 10.0939 15.0952H19.8821C19.9673 15.0952 20.0361 15.1366 20.0361 15.1878C20.0361 15.239 19.9669 15.2804 19.8821 15.2804Z" fill="#B4B4FA"/>
        <path d="M19.8821 18.1647H10.0939C10.0088 18.1647 9.93994 18.1233 9.93994 18.0721C9.93994 18.0209 10.0088 17.9795 10.0939 17.9795H19.8821C19.9673 17.9795 20.0361 18.0209 20.0361 18.0721C20.0361 18.1233 19.9669 18.1647 19.8821 18.1647Z" fill="#B4B4FA"/>
        <path d="M19.8821 7.34756H10.0939C10.0088 7.34756 9.93994 7.30616 9.93994 7.25495C9.93994 7.20375 10.0088 7.16235 10.0939 7.16235H19.8821C19.9673 7.16235 20.0361 7.20375 20.0361 7.25495C20.0361 7.30616 19.9669 7.34756 19.8821 7.34756Z" fill="#B4B4FA"/>
        <path d="M19.8821 10.2321H10.0939C10.0088 10.2321 9.93994 10.1907 9.93994 10.1395C9.93994 10.0883 10.0088 10.0469 10.0939 10.0469H19.8821C19.9673 10.0469 20.0361 10.0883 20.0361 10.1395C20.0361 10.1907 19.9669 10.2321 19.8821 10.2321Z" fill="#B4B4FA"/>
        <path d="M19.8821 13.1168H10.0939C10.0088 13.1168 9.93994 13.0754 9.93994 13.0242C9.93994 12.973 10.0088 12.9316 10.0939 12.9316H19.8821C19.9673 12.9316 20.0361 12.973 20.0361 13.0242C20.0361 13.0754 19.9669 13.1168 19.8821 13.1168Z" fill="#B4B4FA"/>
        <path d="M19.8821 16.0016H10.0939C10.0088 16.0016 9.93994 15.9602 9.93994 15.909C9.93994 15.8578 10.0088 15.8164 10.0939 15.8164H19.8821C19.9673 15.8164 20.0361 15.8578 20.0361 15.909C20.0361 15.9602 19.9669 16.0016 19.8821 16.0016Z" fill="#B4B4FA"/>
        <path d="M19.8821 18.8861H10.0939C10.0088 18.8861 9.93994 18.8447 9.93994 18.7935C9.93994 18.7423 10.0088 18.7009 10.0939 18.7009H19.8821C19.9673 18.7009 20.0361 18.7423 20.0361 18.7935C20.0361 18.8447 19.9669 18.8861 19.8821 18.8861Z" fill="#B4B4FA"/>
        </svg>
    );
}

export default FrameIcon;