





import React from "react"

function BookCopybookIcon() {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4997 28.25C22.0935 28.25 28.2494 22.0939 28.2494 14.5C28.2494 6.90609 22.0935 0.75 14.4997 0.75C6.90595 0.75 0.75 6.90609 0.75 14.5C0.75 22.0939 6.90595 28.25 14.4997 28.25Z" stroke="#B4B4FA" stroke-width="0.5"/>
        <g clip-path="url(#clip0_3506_124)">
        <path d="M22.1191 24.025H7.21343C7.09567 24.025 7.00049 23.9298 7.00049 23.812V4.63809C7.00049 4.52028 7.09567 4.42505 7.21343 4.42505H22.1191C22.2369 4.42505 22.332 4.52028 22.332 4.63809V23.812C22.332 23.9298 22.2366 24.025 22.1191 24.025ZM7.42636 23.599H21.9062V4.85114H7.42636V23.599Z" fill="#B4B4FA"/>
        <path d="M8.55618 14.1909H6.47071C6.37666 14.1909 6.30054 14.116 6.30054 14.0235C6.30054 13.9311 6.37666 13.8562 6.47071 13.8562H8.55618C8.65023 13.8562 8.72635 13.9311 8.72635 14.0235C8.72635 14.116 8.65044 14.1909 8.55618 14.1909Z" fill="#B4B4FA"/>
        <path d="M8.55618 16.2419H6.47071C6.37666 16.2419 6.30054 16.1671 6.30054 16.0746C6.30054 15.9821 6.37666 15.9072 6.47071 15.9072H8.55618C8.65023 15.9072 8.72635 15.9821 8.72635 16.0746C8.72635 16.1671 8.65044 16.2419 8.55618 16.2419Z" fill="#B4B4FA"/>
        <path d="M8.55618 18.2925H6.47071C6.37666 18.2925 6.30054 18.2176 6.30054 18.1251C6.30054 18.0326 6.37666 17.9578 6.47071 17.9578H8.55618C8.65023 17.9578 8.72635 18.0326 8.72635 18.1251C8.72635 18.2176 8.65044 18.2925 8.55618 18.2925Z" fill="#B4B4FA"/>
        <path d="M8.55618 20.3435H6.47071C6.37666 20.3435 6.30054 20.2686 6.30054 20.1761C6.30054 20.0836 6.37666 20.0088 6.47071 20.0088H8.55618C8.65023 20.0088 8.72635 20.0836 8.72635 20.1761C8.72635 20.2686 8.65044 20.3435 8.55618 20.3435Z" fill="#B4B4FA"/>
        <path d="M8.55618 22.394H6.47071C6.37666 22.394 6.30054 22.3192 6.30054 22.2267C6.30054 22.1342 6.37666 22.0593 6.47071 22.0593H8.55618C8.65023 22.0593 8.72635 22.1342 8.72635 22.2267C8.72635 22.3192 8.65044 22.394 8.55618 22.394Z" fill="#B4B4FA"/>
        <path d="M8.55618 10.0893H6.47071C6.37666 10.0893 6.30054 10.0145 6.30054 9.92199C6.30054 9.82949 6.37666 9.75464 6.47071 9.75464H8.55618C8.65023 9.75464 8.72635 9.82949 8.72635 9.92199C8.72635 10.0145 8.65044 10.0893 8.55618 10.0893Z" fill="#B4B4FA"/>
        <path d="M8.55618 12.1399H6.47071C6.37666 12.1399 6.30054 12.065 6.30054 11.9725C6.30054 11.88 6.37666 11.8052 6.47071 11.8052H8.55618C8.65023 11.8052 8.72635 11.88 8.72635 11.9725C8.72635 12.065 8.65044 12.1399 8.55618 12.1399Z" fill="#B4B4FA"/>
        <path d="M8.55618 5.98777H6.47071C6.37666 5.98777 6.30054 5.91292 6.30054 5.82042C6.30054 5.72793 6.37666 5.65308 6.47071 5.65308H8.55618C8.65023 5.65308 8.72635 5.72793 8.72635 5.82042C8.72635 5.91292 8.65044 5.98777 8.55618 5.98777Z" fill="#B4B4FA"/>
        <path d="M8.55618 8.03855H6.47071C6.37666 8.03855 6.30054 7.9637 6.30054 7.87121C6.30054 7.77871 6.37666 7.70386 6.47071 7.70386H8.55618C8.65023 7.70386 8.72635 7.77871 8.72635 7.87121C8.72635 7.9637 8.65044 8.03855 8.55618 8.03855Z" fill="#B4B4FA"/>
        <path d="M18.4562 12.0753H11.3256C10.6609 12.0753 10.1204 11.5438 10.1204 10.8904V8.95397C10.1204 8.30037 10.6611 7.7688 11.3256 7.7688H18.4562C19.1208 7.7688 19.6612 8.30058 19.6612 8.95397V10.8904C19.6612 11.5438 19.1208 12.0753 18.4562 12.0753ZM11.3256 7.97368C10.7758 7.97368 10.3289 8.41337 10.3289 8.95377V10.8902C10.3289 11.4306 10.776 11.87 11.3256 11.87H18.4562C19.0057 11.87 19.4526 11.4304 19.4526 10.8902V8.95377C19.4526 8.41317 19.0055 7.97368 18.4562 7.97368H11.3256Z" fill="#B4B4FA"/>
        <path d="M17.8624 9.22387H11.606C11.5516 9.22387 11.5076 9.1806 11.5076 9.12707C11.5076 9.07355 11.5516 9.03027 11.606 9.03027H17.8624C17.9168 9.03027 17.9608 9.07355 17.9608 9.12707C17.9608 9.1806 17.9166 9.22387 17.8624 9.22387Z" fill="#B4B4FA"/>
        <path d="M17.8624 10.0449H11.606C11.5516 10.0449 11.5076 10.0016 11.5076 9.94812C11.5076 9.89459 11.5516 9.85132 11.606 9.85132H17.8624C17.9168 9.85132 17.9608 9.89459 17.9608 9.94812C17.9608 10.0016 17.9166 10.0449 17.8624 10.0449Z" fill="#B4B4FA"/>
        <path d="M17.8624 10.865H11.606C11.5516 10.865 11.5076 10.8217 11.5076 10.7682C11.5076 10.7147 11.5516 10.6714 11.606 10.6714H17.8624C17.9168 10.6714 17.9608 10.7147 17.9608 10.7682C17.9608 10.8217 17.9166 10.865 17.8624 10.865Z" fill="#B4B4FA"/>
        </g>
        <defs>
        <clipPath id="clip0_3506_124">
        <rect width="20.9996" height="21" fill="white" transform="translate(7.00049 3.02515)"/>
        </clipPath>
        </defs>
        </svg>
        
        
    );
}

export default BookCopybookIcon;