


import React from "react"

function UploadIcon() {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.16659 20.3028C3.60885 19.2937 2.58325 17.5753 2.58325 15.625C2.58325 12.6955 4.89728 10.2891 7.85292 10.0242C8.45751 6.46517 11.6511 3.75 15.4999 3.75C19.3488 3.75 22.5423 6.46517 23.1469 10.0242C26.1026 10.2891 28.4166 12.6955 28.4166 15.625C28.4166 17.5753 27.391 19.2937 25.8333 20.3028M10.3333 20L15.4999 15M15.4999 15L20.6666 20M15.4999 15V26.25" stroke="#7474B8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default UploadIcon;