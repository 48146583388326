import * as React from 'react';
import "./SignUp.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useEffect } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import CapIcon from "../../assets/svg/cap.jsx";
import ORCiDIcon from "../../assets/svg/ORCID.jsx";
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { BASE_URL } from '../../config';

const DEFAULT_DATA = {
    first_name: '',
    last_name: '',
    company: '',
    position: '',
    email: '',
    password: '',
    repeatPassword: '',
    user_type: 'normal'
};

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignUpDialog(props) {
    const {
        openDialog,
        handleCloseDialog,
        onSubmit,
        handleClickAlreadyLogin
    } = props;

    const [open, setOpen] = React.useState(false);
    const [agreeToTerms, setAgreeToTerms] = React.useState(false);
    const [data, setData] = React.useState(DEFAULT_DATA);
    const [samePass, setSamePass] = React.useState(false);
    const [snackbarState, setSnackbarState] = React.useState({
        open: false,
        message: '',
        severity: 'success',
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal } = snackbarState;


    const handleClose = () => {
        setOpen(false);
        handleCloseDialog(false);
    };

    const handleChangeTerms = () => {
        setAgreeToTerms(prevState => !prevState);
    }

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const handleChange = (e, key) => {
        setSamePass(false);
        setData({ ...data, [key]: e.target.value });
    };

    const handleClickSignUp = () => {
        const passMatch = data.password === data.repeatPassword;
        if (passMatch) {
            signUp();
        }
        else {
            setSamePass(true);
        };
    };

    const signUp = async () => {
        const postData = { ...data };
        delete postData.repeatPassword;
        try {
            await axios.post(`${BASE_URL}/auth/register`, postData);
            onSubmit(data);
        } catch ({ response }) {
            setSnackbarState({
                open: true,
                message: response.data.msg,
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarState({ open: false, message: '', severity: '' })
    };

    const snackbarComponent = () => {
        return <Snackbar open={snackbarState.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} onClose={handleCloseSnackbar} severity={snackbarState.severity} sx={{ width: '100%' }}>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    };


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className='signUp-dialog'
                classes={{
                    paper: 'signUp-dialog'
                }}
                scroll='body'
            >
                <div className='dialog-content'>

                    <DialogContent dividers={true}>
                        <DialogContentText>
                            <Typography variant='h3' className='title-signup'>Sign Up </Typography>
                            <Typography variant='h4' className='sub-title-signup'>Fill the Info to create an account</Typography>
                        </DialogContentText>
                        <div className='signup-form'>
                            <input type='text' id='first_name' className='input-custom' placeholder='Enter your username' onChange={(e) => handleChange(e, 'first_name')} />
                            <input type='text' id='last_name' className='input-custom' placeholder='Enter your full name' onChange={(e) => handleChange(e, 'last_name')} />
                            <input type='text' id='company' className='input-custom' placeholder='Enter your company name' onChange={(e) => handleChange(e, 'company')} />
                            <input type='text' id='position' className='input-custom' placeholder='Enter your position' onChange={(e) => handleChange(e, 'position')} />
                            <input type='text' id='email' className='input-custom' placeholder='Enter email' onChange={(e) => handleChange(e, 'email')} />
                            <input type='password' id='password' className='input-custom' placeholder='Enter password' onChange={(e) => handleChange(e, 'password')} />
                            <input type='password' id='repeatPassword' className='input-custom' placeholder='Repeat password' onChange={(e) => handleChange(e, 'repeatPassword')} />
                            {samePass && <p className='error-title'>The password and repeat password fields must be identical. Please make sure they match*</p>}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={agreeToTerms}
                                        onChange={handleChangeTerms}
                                        name="agreeTermsConditions"
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 18 },
                                            color: '#505083',
                                            marginTop: '5px',
                                            '&.Mui-checked': {
                                                color: '#E1E6F2',
                                            },
                                        }} />
                                }
                                label="I agree to the terms & conditions of BIOS Inc."
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: '13px',
                                        marginTop: '5px'
                                    }
                                }}
                            />
                        </div>
                        <Button variant="contained" className="contained-button-sign-up" onClick={handleClickSignUp} disabled={!agreeToTerms}>Sign Up</Button>
                        {/* <div className="divider">or</div>
                        <Button variant="contained" className="contained-button-sign-up" sx={{ color: '#555D74 !important' }}>
                            <ORCiDIcon /> Use ORCiD
                        </Button>
                        <Button variant="contained" className="contained-button-sign-up" sx={{ color: '#555D74 !important', marginTop: '15px !important' }}>
                            <CapIcon />Access Through Your Institution
                        </Button> */}
                        <Typography variant='h3' className='alreadyAcc'>
                            Have an Account? <span onClick={handleClickAlreadyLogin}>Log In</span>
                        </Typography>
                    </DialogContent>
                </div>
            </Dialog>
            {snackbarComponent()}
        </div>
    );
}
