





import React from "react"

function FilterTypeOne() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2063_4039)">
        <path d="M22.9475 17.9732H7.4097C6.27794 17.9732 5.35718 17.0546 5.35718 15.9249V5.28962C5.35718 4.16001 6.27794 3.24109 7.4097 3.24109H22.9475C24.0793 3.24109 25 4.16001 25 5.28962V15.9245C25 17.0543 24.0793 17.9732 22.9475 17.9732ZM7.4097 4.46877C6.95485 4.46877 6.58486 4.83692 6.58486 5.28962V15.9249C6.58486 16.3773 6.95485 16.7456 7.4097 16.7456H22.9475C23.4024 16.7456 23.7724 16.3776 23.7724 15.9245V5.28962C23.7724 4.83692 23.4024 4.46877 22.9475 4.46877H7.4097Z" fill="#B4B4FA"/>
        <path d="M12.5301 8.92205C13.2566 8.92205 13.8455 8.3331 13.8455 7.6066C13.8455 6.88009 13.2566 6.29114 12.5301 6.29114C11.8036 6.29114 11.2146 6.88009 11.2146 7.6066C11.2146 8.3331 11.8036 8.92205 12.5301 8.92205Z" fill="#B4B4FA"/>
        <path d="M16.787 8.13098C16.6152 8.13098 16.4438 8.24377 16.3134 8.46921L13.6671 13.0531C13.4228 12.6292 12.5321 11.0871 12.5321 11.0871C12.402 10.8616 12.2306 10.749 12.059 10.749C11.8876 10.749 11.716 10.8616 11.5859 11.0871C11.5859 11.0871 10.5023 12.9642 10.2416 13.4152L9.84397 14.1044C9.58385 14.5553 9.79639 14.9233 10.3172 14.9233H20.0403C20.5604 14.9233 20.7729 14.5553 20.5139 14.1044L17.2599 8.46921C17.1301 8.24377 16.9588 8.13098 16.787 8.13098Z" fill="#B4B4FA"/>
        </g>
        <path d="M2.70068 19.8259V6.07593" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.22754 7.05805L2.70075 5.58484L4.17397 7.05805" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.17407 21.2991H17.433" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.4507 19.8259L17.9239 21.2991L16.4507 22.7724" stroke="#B4B4FA" strokeWidth="0.45" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.70079 21.7902C2.972 21.7902 3.19186 21.5704 3.19186 21.2992C3.19186 21.028 2.972 20.8081 2.70079 20.8081C2.42958 20.8081 2.20972 21.028 2.20972 21.2992C2.20972 21.5704 2.42958 21.7902 2.70079 21.7902Z" fill="#B4B4FA"/>
        <defs>
        <clipPath id="clip0_2063_4039">
        <rect width="19.6429" height="19.6429" fill="white" transform="translate(5.35718 0.785767)"/>
        </clipPath>
        </defs>
        </svg>
    );
}

export default FilterTypeOne;
