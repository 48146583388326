import React from 'react';
import Header from '../../components/Header';
import "./About.css";
import { Grid, Typography } from '@mui/material';
import LockImage from "../../assets/images/open-lock.png";
import FramesImage from "../../assets/images/Frames.png";
import CellImage from "../../assets/images/cell.png";
import SeoImage from "../../assets/images/seo.png";
import GroupIcon from '../../assets/svg/Group';
import Xicon from '../../assets/svg/iconx';

const About = () => {
    return <div className='App'>
        <Header />
        <div className='about-container'>
            <Typography variant='h2' className='philosphy-title'>
                Our Philosophy
            </Typography>
            <div className='container-phy'>
                <Grid container spacing={4}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <img src={LockImage} alt='lock' />
                        <Typography variant='h3' className='title-sec'>Accessibility</Typography>
                        <Typography variant='h2' className='about-sec-des'>Accessibility is a founding philosophy of BIOS.
                            Based on the accute realization that we learn the same information to become educated & skilled enough to participate,
                            explore and gain experienced in a field; it becomes apparent that accessibility to information for all levels is key
                            to advancement. We strive to be a single access point & reference resource for students, the public, and scientists.</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <img src={FramesImage} alt='frames'/>
                        <Typography variant='h3' className='title-sec'>Transparency</Typography>
                        <Typography variant='h2' className='about-sec-des'>Being open about our sources and ownership and rights of
                            research is integral to the process of creating this knowledge-base and was
                            part of the inspiration behind BIOS. We believe that if we make this information
                            accessible, we should ensure credit and responsibility is given to those who did
                            the work and published the discoveries, therefore this acknowledgement system is
                            built directly into our app with the use of OrciDs.</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <img src={CellImage} alt='cell'/>
                        <Typography variant='h3' className='title-sec'>Exploration</Typography>
                        <Typography variant='h2' className='about-sec-des'>At BIOS we believe that exploration is at the heart
                            of success, without the ability to explore
                            there are no accidents and therefore no discoveries. We want to provide that ability to explore
                            to everyone regardless of social, economic,
                            or academic standing.</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <img src={SeoImage} alt='seo' />
                        <Typography variant='h3' className='title-sec'>Accuracy</Typography>
                        <Typography variant='h2' className='about-sec-des'>Accuracy of information is critical to us at BIOS as without it we would have no purpose.
                            All scientific data relies on how accurate a theory is or isn't. Our goal is to provide all of our users, no matter
                            the experience level or use case, with the most accurate information availble
                            pertaining to their requests. We want our users to also be able to see the edge of their
                            subfield or find the edge quickly.
                            People deserve a resource that emphasizes accuracy & reliablity without fail – BIOS seeks to be this resource.</Typography>
                    </Grid>
                </Grid>
                <Typography variant='h3' className='slogan'>At BIOS, our mission is to bring biology into the digital age to create a virtual world where accessing information becomes intuitive. </Typography>
                <Typography variant='h3' className='team-title'>The BIOS Team</Typography>
                <Typography variant='h3' className='slogan' sx={{ margin: '3% 0px 20% 0px !important' }}>The BIOS team, though small has expertise in big data, several fields of biology, and software development. The BIOS team understands current problems the field has and is actively working to develop solutions.</Typography>
            </div>
        </div>
    </div>;
}

export default About;
