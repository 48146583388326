




import React from "react"

function FilterIcon({ style }) {
    return (
        <svg width="19" height="19" style={style} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_278_3785)">
                <path d="M9.5 19C4.25329 19 0 14.7467 0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19ZM9.5 17.4167C13.8723 17.4167 17.4167 13.8723 17.4167 9.5C17.4167 5.12775 13.8723 1.58333 9.5 1.58333C5.12775 1.58333 1.58333 5.12775 1.58333 9.5C1.58333 13.8723 5.12775 17.4167 9.5 17.4167ZM4.75 6.33333C4.75 5.89611 5.10507 5.54167 5.54362 5.54167H13.4564C13.8947 5.54167 14.25 5.89306 14.25 6.33333C14.25 6.77056 13.8949 7.125 13.4564 7.125H5.54362C5.10532 7.125 4.75 6.77361 4.75 6.33333ZM5.9375 9.5C5.9375 9.06277 6.29083 8.70833 6.72722 8.70833H12.2728C12.7089 8.70833 13.0625 9.05973 13.0625 9.5C13.0625 9.93723 12.7092 10.2917 12.2728 10.2917H6.72722C6.29107 10.2917 5.9375 9.94027 5.9375 9.5ZM7.125 12.6667C7.125 12.2294 7.48202 11.875 7.92004 11.875H11.08C11.519 11.875 11.875 12.2264 11.875 12.6667C11.875 13.1039 11.518 13.4583 11.08 13.4583H7.92004C7.48095 13.4583 7.125 13.1069 7.125 12.6667Z" fill="#A8B0C9" />
            </g>
            <defs>
                <clipPath id="clip0_278_3785">
                    <rect width="19" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default FilterIcon;