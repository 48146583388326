import React from "react";
import Logo from "../../assets/images/logo.png";
import Xicon from "../../assets/svg/iconx";
import GroupIcon from "../../assets/svg/Group";

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="footer-info-container">
                    <div className="footer-bios">
                        <img src={Logo} alt="logo" width={'125px'} height={'35px'} />
                        <div style={{ gap: '10px', display: 'flex' }}>
                            <a href="https://www.linkedin.com/company/70972256/admin/feed/posts/"><GroupIcon /> </a>
                            <a href="https://twitter.com/_BIOSInc"><Xicon /> </a>
                        </div>
                    </div>
                    <div className="info-container">
                        <div className="contacts">
                            <span>Contacts</span>
                            <span className="copyright"><a href="https://airtable.com/apptV3pBdFaUuyN3U/pagojEpbxLYosYQgj/form" className="copyright" style={{ textDecoration: 'none' }}>Media</a></span>
                            <span className="copyright"><a href="https://airtable.com/apptV3pBdFaUuyN3U/paggTJAFxvJrWL32s/form" className="copyright" style={{ textDecoration: 'none' }}>Professional Inquiries</a></span>
                        </div>
                        <div className="contacts">
                            <span >Address</span>
                            <span className="copyright">Biological Information</span>
                            <span className="copyright">OS Inc.</span>
                            <span className="copyright">Tampa, Florida</span>
                            <span className="copyright">USA</span>
                        </div>
                    </div>
                </div>
                <div className="copyRight-container">
                    <div><span className="copyright">©2024 Biological Information OS Inc. All Rights Reserved</span></div>
                    <div className="terms-condition-container">
                        <span className="copyright">Terms and Conditions</span>
                        <span className="copyright">Privacy Statement</span>
                        <span className="copyright"><a href="https://airtable.com/apptV3pBdFaUuyN3U/pag8dMkSzYa98DSht/form" className="copyright" style={{ textDecoration: 'none' }}>Contact us</a></span>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
