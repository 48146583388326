
import React from "react"

function CapIcon() {
    return (
        <svg width="24" style={{ margin: '0px 5px'}}  height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.9704 16.633C21.0212 16.6812 21.0712 16.729 21.1202 16.7766V8.16005C21.1202 7.89496 20.9053 7.68005 20.6402 7.68005C20.3751 7.68005 20.1602 7.89496 20.1602 8.16005V16.7766C20.2092 16.729 20.2591 16.6812 20.31 16.633L20.6402 16.3201L20.9704 16.633ZM20.6402 17.6517C19.6726 18.624 19.2002 19.3795 19.2002 19.8614C19.2002 20.6567 19.8449 21.3014 20.6402 21.3014C21.4355 21.3014 22.0802 20.6567 22.0802 19.8614C22.0802 19.3795 21.6077 18.624 20.6402 17.6517Z" fill="#7AA2FF" />
            <path d="M12.233 2.94041L20.873 7.74041C21.2022 7.92329 21.2022 8.39672 20.873 8.5796L12.233 13.3796C12.088 13.4601 11.9117 13.4601 11.7668 13.3796L3.12677 8.5796C2.79759 8.39672 2.79759 7.92329 3.12677 7.74041L11.7668 2.94041C11.9117 2.85987 12.088 2.85987 12.233 2.94041Z" fill="#004DFF" />
            <path d="M18.2401 11.52L18.2398 15.9467C18.2398 16.0635 18.1972 16.1763 18.12 16.2639C16.9473 17.5953 14.8995 18.24 11.9998 18.24C9.10001 18.24 7.05219 17.5953 5.87956 16.2639C5.80236 16.1763 5.75977 16.0635 5.75977 15.9467L5.76009 11.52L11.3008 14.5982C11.5181 14.7189 11.7589 14.7793 11.9998 14.7794C12.2408 14.7794 12.4819 14.719 12.6994 14.5982L18.2401 11.52Z" fill="#004DFF" />
        </svg>

    );
}

export default CapIcon;

