import React from "react";

function Question() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM11.9 6C10.0222 6 8.5 7.52223 8.5 9.4H10.5C10.5 8.6268 11.1268 8 11.9 8H12.0686C12.8591 8 13.5 8.64086 13.5 9.43141C13.5 9.86624 13.3023 10.2775 12.9628 10.5491L12.6253 10.8191C11.598 11.641 11 12.8852 11 14.2008V15H13V14.2008C13 13.4928 13.3218 12.8232 13.8747 12.3809L14.2122 12.1109C15.0262 11.4597 15.5 10.4738 15.5 9.43141C15.5 7.53629 13.9637 6 12.0686 6H11.9Z"
        fill="#848CA5"
      />
    </svg>
  );
}

export default Question;
