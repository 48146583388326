



import React from "react"

function GroupIcon() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9999 28C21.7319 28 27.9999 21.732 27.9999 14C27.9999 6.26801 21.7319 0 13.9999 0C6.26792 0 -9.15527e-05 6.26801 -9.15527e-05 14C-9.15527e-05 21.732 6.26792 28 13.9999 28Z" fill="white" />
            <path d="M21.9665 15.0726V20.5696H18.7795V15.441C18.7795 14.1532 18.3193 13.2738 17.1654 13.2738C16.2848 13.2738 15.7617 13.8659 15.5306 14.4392C15.4467 14.644 15.4251 14.9285 15.4251 15.2159V20.5694H12.2378C12.2378 20.5694 12.2806 11.8832 12.2378 10.984H15.4254V12.3423C15.4189 12.353 15.4099 12.3635 15.4042 12.3737H15.4254V12.3423C15.8489 11.6906 16.6042 10.7589 18.2977 10.7589C20.3945 10.7589 21.9665 12.1289 21.9665 15.0726ZM8.90367 6.36353C7.81343 6.36353 7.10016 7.07918 7.10016 8.01944C7.10016 8.93973 7.79276 9.67606 8.86184 9.67606H8.88252C9.99414 9.67606 10.6853 8.93973 10.6853 8.01944C10.6642 7.07918 9.99414 6.36353 8.90367 6.36353ZM7.28959 20.5696H10.4757V10.984H7.28959V20.5696Z" fill="#282845" />
        </svg>
    );
}

export default GroupIcon;

