



import React from "react"

function FilterTypeFive() {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2063_4107)">
                <path d="M18.2294 25H0.484216C0.344029 25 0.230713 24.8866 0.230713 24.7463V1.92025C0.230713 1.78 0.344029 1.66663 0.484216 1.66663H18.2294C18.3696 1.66663 18.4829 1.78 18.4829 1.92025V24.7463C18.4829 24.8866 18.3694 25 18.2294 25ZM0.737719 24.4927H17.9759V2.17387H0.737719V24.4927Z" fill="#B4B4FA" />
                <path d="M2.0828 13.2925H-0.399947C-0.511919 13.2925 -0.602539 13.2034 -0.602539 13.0933C-0.602539 12.9832 -0.511919 12.894 -0.399947 12.894H2.0828C2.19478 12.894 2.2854 12.9832 2.2854 13.0933C2.2854 13.2034 2.19502 13.2925 2.0828 13.2925Z" fill="#B4B4FA" />
                <path d="M2.0828 15.7341H-0.399947C-0.511919 15.7341 -0.602539 15.645 -0.602539 15.5349C-0.602539 15.4248 -0.511919 15.3357 -0.399947 15.3357H2.0828C2.19478 15.3357 2.2854 15.4248 2.2854 15.5349C2.2854 15.645 2.19502 15.7341 2.0828 15.7341Z" fill="#B4B4FA" />
                <path d="M2.0828 18.1753H-0.399947C-0.511919 18.1753 -0.602539 18.0862 -0.602539 17.9761C-0.602539 17.866 -0.511919 17.7769 -0.399947 17.7769H2.0828C2.19478 17.7769 2.2854 17.866 2.2854 17.9761C2.2854 18.0862 2.19502 18.1753 2.0828 18.1753Z" fill="#B4B4FA" />
                <path d="M2.0828 20.617H-0.399947C-0.511919 20.617 -0.602539 20.5278 -0.602539 20.4177C-0.602539 20.3076 -0.511919 20.2185 -0.399947 20.2185H2.0828C2.19478 20.2185 2.2854 20.3076 2.2854 20.4177C2.2854 20.5278 2.19502 20.617 2.0828 20.617Z" fill="#B4B4FA" />
                <path d="M2.0828 23.0582H-0.399947C-0.511919 23.0582 -0.602539 22.9691 -0.602539 22.859C-0.602539 22.7489 -0.511919 22.6598 -0.399947 22.6598H2.0828C2.19478 22.6598 2.2854 22.7489 2.2854 22.859C2.2854 22.9691 2.19502 23.0582 2.0828 23.0582Z" fill="#B4B4FA" />
                <path d="M2.0828 8.40956H-0.399947C-0.511919 8.40956 -0.602539 8.32044 -0.602539 8.21033C-0.602539 8.10022 -0.511919 8.01111 -0.399947 8.01111H2.0828C2.19478 8.01111 2.2854 8.10022 2.2854 8.21033C2.2854 8.32044 2.19502 8.40956 2.0828 8.40956Z" fill="#B4B4FA" />
                <path d="M2.0828 10.8508H-0.399947C-0.511919 10.8508 -0.602539 10.7617 -0.602539 10.6516C-0.602539 10.5415 -0.511919 10.4524 -0.399947 10.4524H2.0828C2.19478 10.4524 2.2854 10.5415 2.2854 10.6516C2.2854 10.7617 2.19502 10.8508 2.0828 10.8508Z" fill="#B4B4FA" />
                <path d="M2.0828 3.52686H-0.399947C-0.511919 3.52686 -0.602539 3.43775 -0.602539 3.32764C-0.602539 3.21753 -0.511919 3.12842 -0.399947 3.12842H2.0828C2.19478 3.12842 2.2854 3.21753 2.2854 3.32764C2.2854 3.43775 2.19502 3.52686 2.0828 3.52686Z" fill="#B4B4FA" />
                <path d="M2.0828 5.96839H-0.399947C-0.511919 5.96839 -0.602539 5.87928 -0.602539 5.76917C-0.602539 5.65906 -0.511919 5.56995 -0.399947 5.56995H2.0828C2.19478 5.56995 2.2854 5.65906 2.2854 5.76917C2.2854 5.87928 2.19502 5.96839 2.0828 5.96839Z" fill="#B4B4FA" />
                <path d="M13.8686 10.774H5.37961C4.58835 10.774 3.94482 10.1412 3.94482 9.36337V7.05814C3.94482 6.28004 4.5886 5.64722 5.37961 5.64722H13.8686C14.6599 5.64722 15.3032 6.28029 15.3032 7.05814V9.36337C15.3032 10.1412 14.6599 10.774 13.8686 10.774ZM5.37961 5.89112C4.72515 5.89112 4.1931 6.41457 4.1931 7.0579V9.36313C4.1931 10.0065 4.7254 10.5297 5.37961 10.5297H13.8686C14.5228 10.5297 15.0549 10.0062 15.0549 9.36313V7.0579C15.0549 6.41433 14.5226 5.89112 13.8686 5.89112H5.37961Z" fill="#B4B4FA" />
                <path d="M13.1616 7.37928H5.71338C5.64858 7.37928 5.59619 7.32776 5.59619 7.26404C5.59619 7.20032 5.64858 7.1488 5.71338 7.1488H13.1616C13.2264 7.1488 13.2788 7.20032 13.2788 7.26404C13.2788 7.32776 13.2262 7.37928 13.1616 7.37928Z" fill="#B4B4FA" />
                <path d="M13.1616 8.35657H5.71338C5.64858 8.35657 5.59619 8.30506 5.59619 8.24134C5.59619 8.17761 5.64858 8.1261 5.71338 8.1261H13.1616C13.2264 8.1261 13.2788 8.17761 13.2788 8.24134C13.2788 8.30506 13.2262 8.35657 13.1616 8.35657Z" fill="#B4B4FA" />
                <path d="M13.1616 9.33289H5.71338C5.64858 9.33289 5.59619 9.28138 5.59619 9.21765C5.59619 9.15393 5.64858 9.10242 5.71338 9.10242H13.1616C13.2264 9.10242 13.2788 9.15393 13.2788 9.21765C13.2788 9.28138 13.2262 9.33289 13.1616 9.33289Z" fill="#B4B4FA" />
            </g>
            <defs>
                <clipPath id="clip0_2063_4107">
                    <rect width="25" height="25" fill="white" transform="translate(0.230713)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default FilterTypeFive;