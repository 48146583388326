

import React from "react"

function ObjModel() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5129 8.3233L17.3149 4.16871C17.2716 4.12538 17.212 4.11455 17.1524 4.11996C17.1416 4.11996 17.1362 4.10913 17.1308 4.10913H7.65161C6.97994 4.10913 6.43286 4.65621 6.43286 5.32788V22.672C6.43286 23.3437 6.97994 23.8908 7.65161 23.8908H20.3591C21.0308 23.8908 21.5779 23.3437 21.5779 22.672V8.47496C21.5779 8.46955 21.5724 8.46413 21.5724 8.45871C21.5616 8.40996 21.5454 8.36663 21.5129 8.3233C21.5129 8.32871 21.5129 8.32871 21.5129 8.3233ZM17.3691 4.8133L20.852 8.26371H17.6399C17.5587 8.26371 17.4829 8.23121 17.4233 8.17163C17.3637 8.11205 17.3366 8.03621 17.3366 7.95496L17.3691 4.8133ZM21.1554 22.672C21.1554 23.1108 20.7979 23.4737 20.3537 23.4737H7.6462C7.20203 23.4737 6.84453 23.1162 6.84453 22.672V5.32788C6.84453 4.88371 7.20203 4.52621 7.6462 4.52621H16.9574L16.9249 7.95496C16.9249 8.14996 16.9954 8.33413 17.1362 8.46955C17.2716 8.60496 17.4558 8.6808 17.6508 8.6808H21.1608V22.672H21.1554Z" fill="#7474B8" />
            <path d="M12.8571 18.3441C12.7271 18.2141 12.5754 18.1112 12.4021 18.0354C12.2233 17.9595 12.0283 17.9216 11.8171 17.9216C11.6058 17.9216 11.4108 17.9595 11.2321 18.0354C11.0533 18.1112 10.9017 18.2141 10.7771 18.3441C10.6525 18.4741 10.5496 18.6312 10.4792 18.81C10.4088 18.9887 10.3708 19.1783 10.3708 19.3895C10.3708 19.5954 10.4088 19.7904 10.4792 19.9691C10.5496 20.1479 10.6525 20.2995 10.7771 20.435C10.9017 20.565 11.0588 20.6679 11.2321 20.7437C11.4108 20.8195 11.6058 20.8575 11.8171 20.8575C12.0283 20.8575 12.2233 20.8195 12.4021 20.7437C12.5808 20.6679 12.7325 20.565 12.8571 20.435C12.9817 20.305 13.0846 20.1479 13.155 19.9691C13.2254 19.7904 13.2633 19.6008 13.2633 19.3895C13.2633 19.1837 13.2254 18.9887 13.155 18.81C13.0846 18.6312 12.9871 18.4741 12.8571 18.3441ZM12.7921 19.812C12.7434 19.9475 12.6729 20.0666 12.5808 20.1641C12.4888 20.2616 12.3804 20.3429 12.2504 20.4025C12.1204 20.462 11.9796 20.4891 11.8171 20.4891C11.66 20.4891 11.5138 20.462 11.3838 20.4025C11.2538 20.3429 11.1454 20.2616 11.0533 20.1641C10.9613 20.0666 10.8909 19.9475 10.8421 19.812C10.7934 19.6766 10.7663 19.5358 10.7663 19.3841C10.7663 19.2325 10.7934 19.0916 10.8421 18.9562C10.8909 18.8208 10.9613 18.7016 11.0533 18.6041C11.1454 18.5066 11.2538 18.4254 11.3838 18.3658C11.5138 18.3062 11.6546 18.2791 11.8171 18.2791C11.9796 18.2791 12.1204 18.3062 12.2504 18.3658C12.3804 18.4254 12.4888 18.5066 12.5808 18.6041C12.6729 18.7016 12.7434 18.8208 12.7921 18.9562C12.8409 19.0916 12.8679 19.2325 12.8679 19.3841C12.8625 19.5358 12.8409 19.6766 12.7921 19.812Z" fill="#7474B8" />
            <path d="M15.5653 19.5249C15.5111 19.4653 15.4461 19.4111 15.3757 19.3732C15.2999 19.3353 15.2241 19.3136 15.1374 19.3082V19.3028C15.2782 19.254 15.392 19.1836 15.4841 19.0807C15.5707 18.9778 15.6195 18.8478 15.6195 18.6907C15.6195 18.5715 15.5978 18.4686 15.5545 18.3819C15.5111 18.2953 15.4516 18.2194 15.3757 18.1653C15.2999 18.1111 15.2132 18.0678 15.1103 18.0353C15.0074 18.0082 14.8936 17.9919 14.7745 17.9919H13.8103V20.7707H14.7636C14.8991 20.7707 15.0291 20.7544 15.1428 20.7219C15.262 20.6894 15.3649 20.6407 15.4516 20.5703C15.5382 20.5053 15.6086 20.424 15.6628 20.3265C15.7116 20.229 15.7386 20.1207 15.7386 19.9961C15.7386 19.904 15.7224 19.8119 15.6899 19.7307C15.6628 19.6549 15.6195 19.5844 15.5653 19.5249ZM14.1841 18.3224H14.7745C14.9261 18.3224 15.0399 18.3657 15.1103 18.4469C15.1807 18.5282 15.2186 18.6257 15.2186 18.734C15.2186 18.8099 15.2024 18.8749 15.1753 18.929C15.1482 18.9832 15.1103 19.0265 15.0616 19.059C15.0128 19.0915 14.9586 19.1186 14.8991 19.1294C14.8341 19.1457 14.7691 19.1511 14.6986 19.1511H14.1841V18.3224ZM15.2782 20.2128C15.2349 20.2724 15.1861 20.3211 15.1266 20.3536C15.067 20.3915 15.002 20.4132 14.9316 20.424C14.8611 20.4349 14.7961 20.4403 14.742 20.4403H14.1895V19.4761H14.7474C14.9316 19.4761 15.0778 19.5194 15.1861 19.6007C15.2945 19.6819 15.3486 19.8065 15.3486 19.9744C15.3378 20.0719 15.3161 20.1532 15.2782 20.2128Z" fill="#7474B8" />
            <path d="M17.147 19.8607C17.147 19.9366 17.1416 20.0178 17.1307 20.0937C17.1199 20.1695 17.0982 20.2345 17.0657 20.2941C17.0332 20.3537 16.9899 20.4024 16.9357 20.4403C16.8816 20.4782 16.8057 20.4945 16.7082 20.4945C16.6053 20.4945 16.5187 20.462 16.4537 20.4024C16.3887 20.3428 16.3453 20.2616 16.3237 20.1532L15.9553 20.2345C15.9932 20.4403 16.0799 20.592 16.2099 20.6895C16.3453 20.7924 16.5078 20.8412 16.7082 20.8412C16.7841 20.8412 16.8707 20.8303 16.9682 20.8032C17.0603 20.7762 17.1524 20.7328 17.2337 20.6678C17.3149 20.6028 17.3853 20.5162 17.4395 20.4024C17.4937 20.2887 17.5207 20.1478 17.5207 19.9745V17.9866H17.1416V19.8607H17.147Z" fill="#7474B8" />
            <path d="M17.0496 15.0725C17.0658 15.0562 17.0712 15.04 17.0821 15.0183C17.0929 14.9966 17.1037 14.9804 17.1037 14.9587C17.1037 14.9533 17.1092 14.9533 17.1092 14.9479V10.3112C17.1092 10.2896 17.0929 10.2787 17.0875 10.2625C17.0821 10.2462 17.0875 10.2246 17.0767 10.2137C17.0767 10.2083 17.0712 10.2083 17.0712 10.2029C17.055 10.1866 17.0387 10.1812 17.0171 10.1704C16.9954 10.1596 16.9792 10.1487 16.9575 10.1487C16.9521 10.1487 16.9521 10.1433 16.9467 10.1433H12.31C12.2883 10.1433 12.2775 10.1596 12.2612 10.165C12.245 10.1704 12.2233 10.165 12.2125 10.1758L10.9612 11.0912C10.9558 11.0966 10.9558 11.1021 10.9558 11.1021C10.9396 11.1183 10.9342 11.1346 10.9233 11.1562C10.9125 11.1779 10.9017 11.1941 10.9017 11.2158C10.9017 11.2212 10.8962 11.2212 10.8962 11.2266V15.8633C10.8962 15.885 10.9125 15.8958 10.9179 15.9121C10.9233 15.9283 10.9179 15.95 10.9287 15.9608C10.9342 15.9662 10.9396 15.9662 10.945 15.9716C10.9504 15.9771 10.9558 15.9825 10.9612 15.9879C10.9937 16.015 11.0317 16.0312 11.0696 16.0312H15.7062C15.7387 16.0312 15.7767 16.0204 15.8037 15.9987L17.0442 15.0833C17.0496 15.0833 17.0442 15.0779 17.0496 15.0725ZM12.1367 10.6471V11.0587H11.5787L12.1367 10.6471ZM11.2267 11.3946H12.1367V14.8612L11.2267 15.5275V11.3946ZM15.5221 15.69H11.5787L12.3642 15.1158H15.5275V15.69H15.5221ZM15.5221 14.78H12.4779V11.4H15.5221V14.78ZM15.6358 11.0533H12.4725V10.4791H16.4158L15.6358 11.0533ZM15.8633 15.5275V15.1212H16.4212L15.8633 15.5275ZM16.7733 14.78H15.8633V11.3133L16.7733 10.6471V14.78Z" fill="#7474B8" />
            <rect x="0.825" y="0.825" width="26.35" height="26.35" rx="3.175" stroke="#7474B8" strokeWidth="0.35" strokeLinejoin="round" />
        </svg>
    );
}

export default ObjModel;

