

import React from "react"

function WebsiteLinkIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" fill="#F5F5F5" />
            <g id="My Profile Page" clipPath="url(#clip0_0_1)">
                <rect width="1440" height="1999" transform="translate(-59 -526)" fill="#FEEECD" />
                <g id="Frame 27065">
                    <g id="Group 1209">
                        <g id="1215848_chain_link_share_social_url_icon 1">
                            <g id="Row_1_1_">
                                <g id="Link">
                                    <g id="Group">
                                        <path id="Vector" d="M15.1062 9.77351C14.7172 9.38351 14.0862 9.38351 13.6972 9.77351C13.3072 10.1625 13.3072 10.7945 13.6972 11.1835C13.6972 11.1835 15.6012 13.3195 13.5022 15.4175C12.3032 16.6175 8.77719 20.3615 7.57819 21.5615C6.37919 22.7615 4.46019 22.7865 3.29319 21.6195C2.12519 20.4515 2.15019 18.5325 3.35019 17.3325C4.12219 16.5595 5.24319 15.4385 6.76819 13.9145C6.77019 13.9125 6.77319 13.9125 6.77519 13.9095C7.16419 13.5195 7.16419 12.8895 6.77519 12.4995C6.38519 12.1095 5.75419 12.1095 5.36619 12.4995H5.36319C3.97619 13.8855 3.28319 14.5795 1.94019 15.9225C-0.0588124 17.9225 -0.101812 21.1215 1.84419 23.0675C3.79019 25.0135 6.98919 24.9715 8.98819 22.9715C10.9872 20.9725 12.9132 18.8275 14.9112 16.8285C16.8912 14.8485 17.0402 11.7885 15.1492 9.83651C15.1322 9.81751 15.1262 9.79251 15.1062 9.77351ZM22.9802 1.93251C21.0342 -0.0134923 17.8362 0.0285076 15.8372 2.02851C13.8372 4.02751 11.9112 6.04751 9.91319 8.04651C7.93319 10.0265 7.75419 13.1795 9.64419 15.1315C9.66119 15.1525 9.66719 15.1755 9.68619 15.1955C10.0762 15.5855 10.7062 15.5855 11.0952 15.1955C11.7682 14.5225 11.1392 13.7425 11.1392 13.7425C9.97219 12.5745 10.1222 10.6565 11.3222 9.45751C12.5212 8.25751 16.0472 4.63851 17.2462 3.43851C18.4452 2.23851 20.3642 2.21351 21.5322 3.38051C22.6992 4.54851 22.6742 6.46751 21.4742 7.66751C19.2862 9.85751 18.1342 11.0055 18.0492 11.0905C17.6602 11.4795 17.6602 12.1105 18.0492 12.5005C18.4392 12.8905 19.0702 12.8905 19.4592 12.5005H19.4622C20.8492 11.1135 21.5422 10.4205 22.8862 9.07751C24.8852 7.07751 24.9272 3.87851 22.9802 1.93251Z" fill="#27283B" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="1440" height="1999" fill="white" transform="translate(-59 -526)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default WebsiteLinkIcon;