


import React from "react"

function TriggerIcon() {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6153 2.52699C10.6211 2.43375 10.5896 2.34213 10.5281 2.27387C10.4665 2.20562 10.3805 2.1668 10.2904 2.16663H1.19153C1.1014 2.16667 1.01532 2.20545 0.953762 2.27373C0.892207 2.34202 0.860684 2.43371 0.866692 2.52699C1.02366 4.79403 2.61613 6.67561 4.763 7.13065V11.5789C4.76304 11.71 4.83615 11.8292 4.95057 11.8847C5.06499 11.9403 5.20007 11.9222 5.29715 11.8384L6.59998 10.7122C6.67433 10.6481 6.71731 10.553 6.71723 10.4528V7.13065C8.86485 6.67646 10.4583 4.79463 10.6153 2.52699Z" stroke="#A8B0C9" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default TriggerIcon;