import * as React from 'react';
import "../SignUp/SignUp.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import CapIcon from "../../assets/svg/cap.jsx";
import ORCiDIcon from "../../assets/svg/ORCID.jsx";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logIn } from "../../actions";

const DEFAULT_DATA = {
    email: '',
    password: '',
};

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LogInDialog(props) {
    const {
        openDialog,
        handleCloseDialog,
        onSubmit,
        handleClickSignUpOpt
    } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(DEFAULT_DATA);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleClose = () => {
        setOpen(false);
        handleCloseDialog(false);
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const handleChange = (e, key) => {
        setData({ ...data, [key]: e.target.value });
    };

    const handleClickLogin = () => {
        signIn();
    };

    const signIn = async () => {
        dispatch(logIn(data, () => {
            // success function
            onSubmit(data);
        }, ({ response }) => {
            // api fails
            setSnackbarState({
                open: true,
                message: response?.data?.msg || 'Oops! Something went wrong. Contact to your system administration',
                severity: 'error'
            });
        }, (message) => {
            setSnackbarState({
                open: true,
                message: message || 'Oops! Something went wrong. Contact to your system administration',
                severity: 'error'
            });
            onSubmit(null);
        }))
    };

    const areKeysEmpty = (obj) => {
        for (const key in obj) {
            const value = obj[key];

            if (value === null || value === undefined || value === "") {
                return true;
            }
        }
        return false;
    };

    const handleCloseSnackbar = () => {
        setSnackbarState({ open: false, message: '', severity: '' })
    };

    const snackbarComponent = () => {
        return <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={handleCloseSnackbar} severity={snackbarState.severity} sx={{ width: '100%' }}>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    };

    const loginBtnEnabled = areKeysEmpty(data);

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className='signUp-dialog'
                classes={{
                    paper: 'signUp-dialog'
                }}
                scroll='paper'
            >
                <div className='dialog-content'>

                    <DialogContent dividers={true}>
                        <DialogContentText>
                            <Typography variant='h3' className='title-signup'>Log In </Typography>
                        </DialogContentText>
                        <div className='signup-form'>
                            <input type='text' id='email-login' className='input-custom' placeholder='Your email' onChange={(e) => handleChange(e, 'email')} />
                            <input type='password' id='password-login' className='input-custom' placeholder='Your password' onChange={(e) => handleChange(e, 'password')} />

                            <Typography variant='h3' className='forget-title'>Forgot Password?</Typography>
                        </div>
                        <Button variant="contained" className="contained-button-sign-up" disabled={loginBtnEnabled} onClick={handleClickLogin}>Login</Button>
                        {/* <div className="divider">or</div>
                        <Button variant="contained" className="contained-button-sign-up" sx={{ color: '#555D74 !important' }}>
                            <ORCiDIcon /> Use ORCiD
                        </Button>
                        <Button variant="contained" className="contained-button-sign-up" sx={{ color: '#555D74 !important', marginTop: '15px !important' }}>
                            <CapIcon />Access Through Your Institution
                        </Button> */}
                        <Typography variant='h3' className='alreadyAcc'>
                            Not a Member yet? <span onClick={handleClickSignUpOpt}>Sign Up</span>
                        </Typography>
                    </DialogContent>
                </div>
            </Dialog>
            {snackbarComponent()}
        </div>
    );
}
