import React from "react";

function FileIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.825"
        y="0.825"
        width="20.35"
        height="20.35"
        rx="3.175"
        stroke="#7474B8"
        stroke-width="0.35"
        stroke-linejoin="round"
      />
      <path
        d="M16.7792 6.63327L13.55 3.43743C13.5167 3.4041 13.4709 3.39577 13.425 3.39994C13.4167 3.39994 13.4125 3.3916 13.4084 3.3916H6.1167C5.60003 3.3916 5.1792 3.81243 5.1792 4.3291V17.6708C5.1792 18.1874 5.60003 18.6083 6.1167 18.6083H15.8917C16.4084 18.6083 16.8292 18.1874 16.8292 17.6708V6.74993C16.8292 6.74577 16.825 6.7416 16.825 6.73743C16.8167 6.69993 16.8042 6.6666 16.7792 6.63327C16.7792 6.63743 16.7792 6.63743 16.7792 6.63327ZM13.5917 3.93327L16.2709 6.58743H13.8C13.7375 6.58743 13.6792 6.56243 13.6334 6.5166C13.5875 6.47077 13.5667 6.41243 13.5667 6.34993L13.5917 3.93327ZM16.5042 17.6708C16.5042 18.0083 16.2292 18.2874 15.8875 18.2874H6.11253C5.77087 18.2874 5.49587 18.0124 5.49587 17.6708V4.3291C5.49587 3.98743 5.77087 3.71244 6.11253 3.71244H13.275L13.25 6.34993C13.25 6.49993 13.3042 6.6416 13.4125 6.74577C13.5167 6.84993 13.6584 6.90827 13.8084 6.90827H16.5084V17.6708H16.5042Z"
        fill="#7474B8"
      />
      <path
        d="M10.1208 14.3416C10.0208 14.2416 9.90409 14.1624 9.77075 14.1041C9.63325 14.0458 9.48325 14.0166 9.32075 14.0166C9.15825 14.0166 9.00825 14.0458 8.87075 14.1041C8.73325 14.1624 8.61659 14.2416 8.52075 14.3416C8.42492 14.4416 8.34575 14.5624 8.29159 14.6999C8.23742 14.8374 8.20825 14.9833 8.20825 15.1458C8.20825 15.3041 8.23742 15.4541 8.29159 15.5916C8.34575 15.7291 8.42492 15.8458 8.52075 15.9499C8.61659 16.0499 8.73742 16.1291 8.87075 16.1874C9.00825 16.2458 9.15825 16.2749 9.32075 16.2749C9.48325 16.2749 9.63325 16.2458 9.77075 16.1874C9.90825 16.1291 10.0249 16.0499 10.1208 15.9499C10.2166 15.8499 10.2958 15.7291 10.3499 15.5916C10.4041 15.4541 10.4333 15.3083 10.4333 15.1458C10.4333 14.9874 10.4041 14.8374 10.3499 14.6999C10.2958 14.5624 10.2208 14.4416 10.1208 14.3416ZM10.0708 15.4708C10.0333 15.5749 9.97909 15.6666 9.90825 15.7416C9.83742 15.8166 9.75409 15.8791 9.65409 15.9249C9.55409 15.9708 9.44575 15.9916 9.32075 15.9916C9.19992 15.9916 9.08742 15.9708 8.98742 15.9249C8.88742 15.8791 8.80409 15.8166 8.73325 15.7416C8.66242 15.6666 8.60825 15.5749 8.57075 15.4708C8.53325 15.3666 8.51242 15.2583 8.51242 15.1416C8.51242 15.0249 8.53325 14.9166 8.57075 14.8124C8.60825 14.7083 8.66242 14.6166 8.73325 14.5416C8.80409 14.4666 8.88742 14.4041 8.98742 14.3583C9.08742 14.3124 9.19575 14.2916 9.32075 14.2916C9.44575 14.2916 9.55409 14.3124 9.65409 14.3583C9.75409 14.4041 9.83742 14.4666 9.90825 14.5416C9.97909 14.6166 10.0333 14.7083 10.0708 14.8124C10.1083 14.9166 10.1291 15.0249 10.1291 15.1416C10.1249 15.2583 10.1083 15.3666 10.0708 15.4708Z"
        fill="#7474B8"
      />
      <path
        d="M12.2042 15.25C12.1626 15.2041 12.1126 15.1625 12.0584 15.1333C12.0001 15.1041 11.9417 15.0875 11.8751 15.0833V15.0791C11.9834 15.0416 12.0709 14.9875 12.1417 14.9083C12.2084 14.8291 12.2459 14.7291 12.2459 14.6083C12.2459 14.5166 12.2292 14.4375 12.1959 14.3708C12.1626 14.3041 12.1167 14.2458 12.0584 14.2041C12.0001 14.1625 11.9334 14.1291 11.8542 14.1041C11.7751 14.0833 11.6876 14.0708 11.5959 14.0708H10.8542V16.2083H11.5876C11.6917 16.2083 11.7917 16.1958 11.8792 16.1708C11.9709 16.1458 12.0501 16.1083 12.1167 16.0541C12.1834 16.0041 12.2376 15.9416 12.2792 15.8666C12.3167 15.7916 12.3376 15.7083 12.3376 15.6125C12.3376 15.5416 12.3251 15.4708 12.3001 15.4083C12.2792 15.35 12.2459 15.2958 12.2042 15.25ZM11.1417 14.325H11.5959C11.7126 14.325 11.8001 14.3583 11.8542 14.4208C11.9084 14.4833 11.9376 14.5583 11.9376 14.6416C11.9376 14.7 11.9251 14.75 11.9042 14.7916C11.8834 14.8333 11.8542 14.8666 11.8167 14.8916C11.7792 14.9166 11.7376 14.9375 11.6917 14.9458C11.6417 14.9583 11.5917 14.9625 11.5376 14.9625H11.1417V14.325ZM11.9834 15.7791C11.9501 15.825 11.9126 15.8625 11.8667 15.8875C11.8209 15.9166 11.7709 15.9333 11.7167 15.9416C11.6626 15.95 11.6126 15.9541 11.5709 15.9541H11.1459V15.2125H11.5751C11.7167 15.2125 11.8292 15.2458 11.9126 15.3083C11.9959 15.3708 12.0376 15.4666 12.0376 15.5958C12.0292 15.6708 12.0126 15.7333 11.9834 15.7791Z"
        fill="#7474B8"
      />
      <path
        d="M13.4208 15.5083C13.4208 15.5667 13.4167 15.6292 13.4083 15.6875C13.4 15.7458 13.3833 15.7958 13.3583 15.8416C13.3333 15.8875 13.3 15.925 13.2583 15.9541C13.2167 15.9833 13.1583 15.9958 13.0833 15.9958C13.0042 15.9958 12.9375 15.9708 12.8875 15.925C12.8375 15.8791 12.8042 15.8167 12.7875 15.7333L12.5042 15.7958C12.5333 15.9541 12.6 16.0708 12.7 16.1458C12.8042 16.225 12.9292 16.2625 13.0833 16.2625C13.1417 16.2625 13.2083 16.2542 13.2833 16.2333C13.3541 16.2125 13.425 16.1792 13.4875 16.1292C13.55 16.0792 13.6042 16.0125 13.6458 15.925C13.6875 15.8375 13.7083 15.7291 13.7083 15.5958V14.0667H13.4166V15.5083H13.4208Z"
        fill="#7474B8"
      />
      <path
        d="M13.3459 11.8249C13.3584 11.8124 13.3625 11.7999 13.3709 11.7832C13.3792 11.7665 13.3875 11.754 13.3875 11.7374C13.3875 11.7332 13.3917 11.7332 13.3917 11.729V8.16237C13.3917 8.1457 13.3792 8.13737 13.375 8.12487C13.3709 8.11237 13.375 8.0957 13.3667 8.08737C13.3667 8.0832 13.3625 8.0832 13.3625 8.07904C13.35 8.06654 13.3375 8.06237 13.3209 8.05404C13.3042 8.0457 13.2917 8.03737 13.275 8.03737C13.2709 8.03737 13.2709 8.0332 13.2667 8.0332H9.70005C9.68338 8.0332 9.67505 8.0457 9.66255 8.04987C9.65005 8.05404 9.63338 8.04987 9.62505 8.0582L8.66255 8.76237C8.65838 8.76654 8.65838 8.7707 8.65838 8.7707C8.64588 8.7832 8.64172 8.7957 8.63338 8.81237C8.62505 8.82904 8.61672 8.84154 8.61672 8.8582C8.61672 8.86237 8.61255 8.86237 8.61255 8.86654V12.4332C8.61255 12.4499 8.62505 12.4582 8.62922 12.4707C8.63338 12.4832 8.62922 12.4999 8.63755 12.5082C8.64172 12.5124 8.64588 12.5124 8.65005 12.5165C8.65422 12.5207 8.65838 12.5249 8.66255 12.529C8.68755 12.5499 8.71672 12.5624 8.74588 12.5624H12.3125C12.3375 12.5624 12.3667 12.554 12.3875 12.5374L13.3417 11.8332C13.3459 11.8332 13.3417 11.829 13.3459 11.8249ZM9.56672 8.4207V8.73737H9.13755L9.56672 8.4207ZM8.86671 8.9957H9.56672V11.6624L8.86671 12.1749V8.9957ZM12.1709 12.2999H9.13755L9.74172 11.8582H12.175V12.2999H12.1709ZM12.1709 11.5999H9.82922V8.99987H12.1709V11.5999ZM12.2584 8.7332H9.82505V8.29154H12.8584L12.2584 8.7332ZM12.4334 12.1749V11.8624H12.8625L12.4334 12.1749ZM13.1334 11.5999H12.4334V8.9332L13.1334 8.4207V11.5999Z"
        fill="#7474B8"
      />
    </svg>
  );
}

export default FileIcon;
