import React, { Suspense, useRef } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three-stdlib/loaders/GLTFLoader";
import { PerspectiveCamera, OrbitControls } from "@react-three/drei";
import Heart from "./Heart.jsx";

function Model({ url = "" }) {
  const gltf = useLoader(GLTFLoader, url);
  const modelRef = useRef();

  // Rotation animation
  useFrame(() => {
    // Rotate the model
    // modelRef.current.rotation.x += 0.00;
    modelRef.current.rotation.y += 0.00;
  });
  // Scale up the model. You can adjust these values as needed.
  const scale = [1, 1, 1]; // This scales the model to double its size
  return <primitive object={gltf.scene} scale={scale} ref={modelRef} />;
}

function Scene({ url = "" }) {
  return (
    <>
      <PerspectiveCamera position={[0, 0, 5]}>
        <OrbitControls enableZoom={true} />
      </PerspectiveCamera>

      {/* Lighting */}
      <ambientLight intensity={3.0} />
      <directionalLight position={[-8, 5, 2]} intensity={1.5} castShadow />
      <pointLight position={[10, 10, 10]} intensity={3.5} />

      <Suspense fallback={null}>
        {/* <Heart /> */}
        url && <Model url={url} />
      </Suspense>
    </>
  );
}

function ThreeScene({ url = "" }) {
  return (
    <Canvas
      style={{ background: "rgba(255, 255, 255, 0.05)", height: "500px" }}
    >
      {url ? <Scene url={url} /> : <></>}
    </Canvas>
  );
}

export default ThreeScene;
