import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Footer from "../components/Footer";
import MyAccount from "../pages/MyAccount";
import Platform from "../pages/Platform";
import AllDialogs from "../components/AllDialogs";
import Visualization from "../pages/Visualization";

function MyRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/visualization" element={<Visualization />} />
        {/* You can add more routes as needed */}
      </Routes>
      <Footer />
      <AllDialogs />
    </Router>
  );
}

export default MyRoutes;
