import React from "react"

function SvgTwoIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="9.99988" r="9.25" stroke="#B4B4FA" strokeWidth="0.5" />
            <g clipPath="url(#clip0_2022_8694)">
                <path d="M3.32927 7.5395C3.51037 7.5395 3.65854 7.39133 3.65854 7.21023V5.64621H5.22256C5.40366 5.64621 5.55183 5.49804 5.55183 5.31694C5.55183 5.13584 5.40366 4.98767 5.22256 4.98767H3.32927C3.14817 4.98767 3 5.13584 3 5.31694V7.21023C3 7.39133 3.14817 7.5395 3.32927 7.5395Z" fill="#B4B4FA" />
                <path d="M4.72866 12.8901H3.65854V11.3261C3.65854 11.145 3.51037 10.9968 3.32927 10.9968C3.14817 10.9968 3 11.145 3 11.3261V13.2194C3 13.4005 3.14817 13.5487 3.32927 13.5487H4.72866C4.90976 13.5487 5.05793 13.4005 5.05793 13.2194C5.05793 13.0383 4.90976 12.8901 4.72866 12.8901Z" fill="#B4B4FA" />
                <path d="M13.2074 4.98767H11.3141C11.133 4.98767 10.9849 5.13584 10.9849 5.31694C10.9849 5.49804 11.133 5.64621 11.3141 5.64621H12.8782V6.38706C12.8782 6.56816 13.0263 6.5517 13.2074 6.5517C13.3885 6.5517 13.5367 6.56816 13.5367 6.38706V5.31694C13.5367 5.13584 13.3885 4.98767 13.2074 4.98767Z" fill="#B4B4FA" />
            </g>
            <g clipPath="url(#clip1_2022_8694)">
                <path d="M4.81071 8.52705C4.99181 8.52705 5.13998 8.37888 5.13998 8.19778V6.63376H6.70401C6.8851 6.63376 7.03327 6.48559 7.03327 6.30449C7.03327 6.12339 6.8851 5.97522 6.70401 5.97522H4.81071C4.62962 5.97522 4.48145 6.12339 4.48145 6.30449V8.19778C4.48145 8.37888 4.62962 8.52705 4.81071 8.52705Z" fill="#B4B4FA" />
                <path d="M6.12779 13.8777H5.13998V12.3136C5.13998 12.1325 4.99181 11.9844 4.81071 11.9844C4.62962 11.9844 4.48145 12.1325 4.48145 12.3136V14.2069C4.48145 14.388 4.62962 14.5362 4.81071 14.5362H6.12779C6.30888 14.5362 6.45706 14.388 6.45706 14.2069C6.45706 14.0258 6.30888 13.8777 6.12779 13.8777Z" fill="#B4B4FA" />
                <path d="M14.6889 5.97522H12.7956C12.6145 5.97522 12.4663 6.12339 12.4663 6.30449C12.4663 6.48559 12.6145 6.63376 12.7956 6.63376H14.3596V7.29229C14.3596 7.47339 14.5078 7.62156 14.6889 7.62156C14.87 7.62156 15.0181 7.47339 15.0181 7.29229V6.30449C15.0181 6.12339 14.87 5.97522 14.6889 5.97522Z" fill="#B4B4FA" />
            </g>
            <g clipPath="url(#clip2_2022_8694)">
                <path d="M6.2924 9.51521C6.4735 9.51521 6.62167 9.36704 6.62167 9.18594V7.62192H8.1857C8.36679 7.62192 8.51496 7.47374 8.51496 7.29265C8.51496 7.11155 8.36679 6.96338 8.1857 6.96338H6.2924C6.11131 6.96338 5.96313 7.11155 5.96313 7.29265V9.18594C5.96313 9.36704 6.11131 9.51521 6.2924 9.51521Z" fill="#B4B4FA" />
                <path d="M8.1857 14.8658H6.62167V13.3018C6.62167 13.1207 6.4735 12.9725 6.2924 12.9725C6.11131 12.9725 5.96313 13.1207 5.96313 13.3018V15.1951C5.96313 15.3762 6.11131 15.5244 6.2924 15.5244H8.1857C8.36679 15.5244 8.51496 15.3762 8.51496 15.1951C8.51496 15.014 8.36679 14.8658 8.1857 14.8658Z" fill="#B4B4FA" />
                <path d="M16.1706 12.9725C15.9895 12.9725 15.8413 13.1207 15.8413 13.3018V14.8658H14.2773C14.0962 14.8658 13.948 15.014 13.948 15.1951C13.948 15.3762 14.0962 15.5244 14.2773 15.5244H16.1706C16.3517 15.5244 16.4998 15.3762 16.4998 15.1951V13.3018C16.4998 13.1207 16.3517 12.9725 16.1706 12.9725Z" fill="#B4B4FA" />
                <path d="M16.1706 6.96338H14.2773C14.0962 6.96338 13.948 7.11155 13.948 7.29265C13.948 7.47374 14.0962 7.62192 14.2773 7.62192H15.8413V9.18594C15.8413 9.36704 15.9895 9.51521 16.1706 9.51521C16.3517 9.51521 16.4998 9.36704 16.4998 9.18594V7.29265C16.4998 7.11155 16.3517 6.96338 16.1706 6.96338Z" fill="#B4B4FA" />
                <path d="M12.8119 11.5731H14.0796C14.2607 11.5731 14.4088 11.4249 14.4088 11.2438C14.4088 11.0627 14.2607 10.9146 14.0796 10.9146H12.8119C12.6802 10.289 12.1863 9.79507 11.5607 9.66336V8.39567C11.5607 8.21458 11.4125 8.06641 11.2314 8.06641C11.0503 8.06641 10.9021 8.21458 10.9021 8.39567V9.66336C10.2765 9.79507 9.78261 10.289 9.65091 10.9146H8.38322C8.20213 10.9146 8.05396 11.0627 8.05396 11.2438C8.05396 11.4249 8.20213 11.5731 8.38322 11.5731H9.65091C9.78261 12.1987 10.2765 12.6926 10.9021 12.8243V14.092C10.9021 14.2731 11.0503 14.4213 11.2314 14.4213C11.4125 14.4213 11.5607 14.2731 11.5607 14.092V12.8243C12.1863 12.6926 12.6802 12.1987 12.8119 11.5731ZM11.2314 12.1987C10.7046 12.1987 10.2765 11.7707 10.2765 11.2438C10.2765 10.717 10.7046 10.289 11.2314 10.289C11.7582 10.289 12.1863 10.717 12.1863 11.2438C12.1863 11.7707 11.7582 12.1987 11.2314 12.1987Z" fill="#B4B4FA" />
            </g>
            <defs>
                <clipPath id="clip0_2022_8694">
                    <rect width="10.5366" height="10.5366" fill="white" transform="translate(3 3.99988)" />
                </clipPath>
                <clipPath id="clip1_2022_8694">
                    <rect width="10.5366" height="10.5366" fill="white" transform="translate(4.48145 4.98743)" />
                </clipPath>
                <clipPath id="clip2_2022_8694">
                    <rect width="10.5366" height="10.5366" fill="white" transform="translate(5.96313 5.97559)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgTwoIcon;