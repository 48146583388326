



import React from "react"

function EyeIcon() {
    return (
        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4763 12.2187C21.462 12.1978 21.4572 12.1718 21.4428 12.151C21.2607 11.9322 16.9434 6.77075 11.5001 6.77075C6.05676 6.77075 1.73947 11.9322 1.55738 12.151C1.54301 12.1718 1.53342 12.1978 1.51905 12.2187C1.49988 12.2447 1.48551 12.276 1.47113 12.3072C1.46155 12.3385 1.45197 12.3697 1.44717 12.4062C1.44717 12.4374 1.44238 12.4687 1.44238 12.4999C1.44238 12.5312 1.44717 12.5624 1.45676 12.5989C1.46155 12.6301 1.46634 12.6666 1.48072 12.6978C1.4903 12.7291 1.50947 12.7551 1.52863 12.7864C1.54301 12.8072 1.5478 12.8333 1.56217 12.8541C1.73947 13.0676 6.05676 18.2291 11.5001 18.2291C16.9434 18.2291 21.2607 13.0676 21.4428 12.8489C21.4572 12.828 21.4668 12.802 21.4811 12.7812C21.5003 12.7551 21.5147 12.7239 21.5291 12.6926C21.5386 12.6614 21.5482 12.6301 21.553 12.5937C21.5578 12.5624 21.5674 12.5312 21.5674 12.4947C21.5674 12.4635 21.5626 12.4322 21.553 12.3958C21.5482 12.3645 21.5434 12.328 21.5291 12.2968C21.5099 12.276 21.4955 12.2447 21.4763 12.2187ZM11.5001 17.1874C7.25947 17.1874 3.63217 13.6301 2.5828 12.4999C3.62738 11.3697 7.24988 7.81242 11.5001 7.81242C15.7407 7.81242 19.368 11.3697 20.4174 12.4999C19.3728 13.6301 15.7455 17.1874 11.5001 17.1874Z" fill="white" />
            <path d="M7.66675 12.4999C7.66675 14.7968 9.38696 16.6666 11.5001 16.6666C13.6132 16.6666 15.3334 14.7968 15.3334 12.4999C15.3334 10.203 13.6132 8.33325 11.5001 8.33325C9.38696 8.33325 7.66675 10.203 7.66675 12.4999ZM14.3751 12.4999C14.3751 14.2239 13.0861 15.6249 11.5001 15.6249C9.91404 15.6249 8.62508 14.2239 8.62508 12.4999C8.62508 10.776 9.91404 9.37492 11.5001 9.37492C13.0861 9.37492 14.3751 10.776 14.3751 12.4999Z" fill="white" />
            <path d="M10.0625 12.5C10.0625 13.3594 10.7094 14.0625 11.5 14.0625C12.2906 14.0625 12.9375 13.3594 12.9375 12.5C12.9375 11.6406 12.2906 10.9375 11.5 10.9375C10.7094 10.9375 10.0625 11.6406 10.0625 12.5ZM11.9792 12.5C11.9792 12.7865 11.7635 13.0208 11.5 13.0208C11.2365 13.0208 11.0208 12.7865 11.0208 12.5C11.0208 12.2135 11.2365 11.9792 11.5 11.9792C11.7635 11.9792 11.9792 12.2135 11.9792 12.5Z" fill="white" />
        </svg>



    );
}

export default EyeIcon;