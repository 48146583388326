import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/heart.gltf');
  
  const heartRef = useRef();
  let scale = 1;
  let direction = 0.0008;  // Speed and direction of scaling
  
  useFrame(() => {
    if (heartRef.current) {
      scale += direction;

      // Switch direction of scaling when limits are reached
      if (scale > 1.05 || scale < 0.95) {
        direction = -direction;
      }

      heartRef.current.scale.set(scale, scale, scale);
    }
  });

  return (
    <group ref={heartRef} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.174}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.03}>
          <mesh geometry={nodes.hartZBrush_defualt_group_Heart_Tex_0.geometry} material={materials.Heart_Tex} scale={286.365} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/heart.gltf');
