

import React from "react"

function OrcidIcon() {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_521_2235)">
                <path d="M25.5876 12.5C25.5876 19.4043 19.9919 25 13.0876 25C6.18335 25 0.587646 19.4043 0.587646 12.5C0.587646 5.5957 6.18335 0 13.0876 0C19.9919 0 25.5876 5.5957 25.5876 12.5Z" fill="#1B1C30" />
                <path d="M9.01538 18.1836H7.51147V7.72461H9.01538V12.4512V18.1836Z" fill="#FEEECD" />
                <path d="M11.2224 7.72461H15.2849C19.1521 7.72461 20.8513 10.4883 20.8513 12.959C20.8513 15.6445 18.7517 18.1934 15.3044 18.1934H11.2224V7.72461ZM12.7263 16.8359H15.1189C18.5271 16.8359 19.3083 14.248 19.3083 12.959C19.3083 10.8594 17.9705 9.08203 15.0408 9.08203H12.7263V16.8359Z" fill="#FEEECD" />
                <path d="M9.24976 5.54687C9.24976 6.08398 8.8103 6.5332 8.26343 6.5332C7.71655 6.5332 7.2771 6.08398 7.2771 5.54687C7.2771 5 7.71655 4.56055 8.26343 4.56055C8.8103 4.56055 9.24976 5.00977 9.24976 5.54687Z" fill="#FEEECD" />
            </g>
            <defs>
                <clipPath id="clip0_521_2235">
                    <rect width="25" height="25" fill="white" transform="translate(0.587646)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default OrcidIcon;