

import React from "react"

function LinkedinIcon() {
    return (
        <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8887 22.3017H19.1845V16.5005C19.1845 15.1172 19.1598 13.3364 17.2579 13.3364C15.3286 13.3364 15.0335 14.8436 15.0335 16.3998V22.3013H11.3292V10.3718H14.8853V12.0021H14.9351C15.2909 11.3936 15.8052 10.893 16.423 10.5536C17.0409 10.2143 17.7392 10.0489 18.4437 10.075C22.198 10.075 22.8903 12.5446 22.8903 15.7573L22.8887 22.3017ZM7.14963 8.74108C6.72448 8.74116 6.30885 8.61516 5.9553 8.37901C5.60176 8.14287 5.3262 7.80718 5.16343 7.41441C5.00066 7.02163 4.958 6.58941 5.04087 6.1724C5.12374 5.75539 5.32841 5.37232 5.62898 5.07163C5.92956 4.77094 6.31254 4.56613 6.72951 4.48311C7.14648 4.40009 7.57869 4.44258 7.97151 4.60521C8.36433 4.76785 8.7001 5.04332 8.93636 5.39679C9.17263 5.75026 9.29878 6.16585 9.29886 6.59102C9.29891 6.87332 9.24335 7.15286 9.13537 7.41369C9.02739 7.67452 8.86911 7.91153 8.66953 8.11118C8.46996 8.31083 8.233 8.46922 7.97222 8.5773C7.71143 8.68538 7.43192 8.74103 7.14963 8.74108ZM9.00174 22.3017H5.29367V10.3718H9.00174V22.3017ZM24.7354 1.0017H3.43231C2.94879 0.996247 2.48286 1.18298 2.13694 1.52088C1.79103 1.85877 1.59343 2.32018 1.58752 2.80371V24.1959C1.59322 24.6797 1.79071 25.1414 2.13661 25.4797C2.48251 25.8179 2.94855 26.005 3.43231 25.9999H24.7354C25.2201 26.0059 25.6875 25.8194 26.0347 25.4811C26.382 25.1429 26.5808 24.6806 26.5875 24.1959V2.80217C26.5806 2.31768 26.3817 1.85574 26.0344 1.51786C25.6871 1.17997 25.2199 0.993769 24.7354 1.00016" fill="#1B1C30"/>
        </svg>
        
    );
}

export default LinkedinIcon;