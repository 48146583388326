const initialState = {
  user: null,
  userProfile: null,
  loginDialog: false,
  signUpDialog: false,
  profileDialog: false,
  otpDialog: false,
  user_image_url: "",
  uploadedModelsState: [],
  favModelsState: [],
};

export default initialState;
